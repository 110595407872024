import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import Evaluations from './Company/Evaluations'

import { useStore, useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";




export const EvaluationsPage = (props) => {
 
  
  return (
    <DashboardLayout>
     <Evaluations/>
    </DashboardLayout>
  )
}
