import React, { useState } from 'react'
import MediaCourse from './Course/Media'
import DashboardLayout from '../components/layout/DashboardLayout'
import Stepper from './Course/Stepper'
import CourseForm from './Course/CourseForm'
import CourseContent from './Course/CourseContent/CourseContent'
import { useSelector } from 'react-redux'



export const CreateCourse = () => {
  const [step, setStep] = useState(0)
  const [data_temp , setDataTemp] = useState({course:{}, media:{},topics:{}})
  const translate = useSelector((state) => state.translate)

  const nextStep = (data_step) => {
  setTimeout(()=>{
  if(step === 0){
    console.clear()
    console.log("rdt ",data_step)
       setDataTemp({course:data_step})
       
       console.log("basic ")
 
  }

  if(step === 1){
    setDataTemp({...data_temp.course, media:data_step})
    
    console.log("media ")
  
  }

  if(step === 2){
  
    console.log("topics ")
  }
  
 console.log("dtemp ",data_temp)
    
  setStep(step + 1)
  
},500)

}


  return (
    <DashboardLayout>
      <h2>
        <i className="fa fa-plus"></i> {translate.GENERAL.CREATE_COURSE}
      </h2>
      <Stepper step={step} />
      {step === 0 && <CourseForm nextStep={nextStep} />}
      {step === 1 && <MediaCourse nextStep={nextStep} course={window.localStorage.getItem('idcs')}/>}
      {step === 2 && <CourseContent nextStep={nextStep} course={window.localStorage.getItem('idcs')}/>}
    </DashboardLayout>
  )
}
