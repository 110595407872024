import React, { useEffect, useState } from 'react'
import axios from 'axios'
import courseImg from '../../images/courses/img-2.jpg'
import DetailsTabPanel from './courseDetailLayout'
import TopicsPanel from './topicsPanel'
import {Button, Divider, Embed, List, Card, Image, Accordion, Icon,Tab } from 'semantic-ui-react'
import './CourseView.scss'
import LessonView from './LessonView'
import { store, setCourseData,setActiveLesson ,setMicoursesUserData} from '../../reducers/index'
import { useStore, useSelector } from 'react-redux'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import CommentsLessons from './LessonComments'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";



const CourseContentG = (props) => {

  const [coursed, setCourse] = useState({title:''})
  const [topicsd, settopics] = useState([])

  const [autoplay, setautoplay] = useState(true)

  const [user, setuser] = useState(useSelector((store) => store.user) )
  const [my_courses, set_my_courses] = useState(useSelector((store) => store.my_courses) )
  store.dispatch(setActiveLesson({}))

//Get Javascript object equivalent to Proxy
const proxyToObj = (obj) =>{
  return JSON.parse(JSON.stringify(obj));
}

const reload_m = (data)=>{
  set_my_courses(data)

  store.dispatch(setMicoursesUserData(data))

     
  
}

const reload_course2 = async (params) => {
 // console.clear()

  console.log('reload myc ',my_courses)

var courseClone = proxyToObj(coursed)
console.log(courseClone)


courseClone.topics.map((topic,index)=>{
topic.lessons.map((lesson,indexls)=>{

if(lesson._id === params.lesson_data._id){
  console.log("seteando la lista")

  courseClone.topics[index].lessons[indexls].status="lesson_completed"
 

}

})

  
})

console.log("cambios ",courseClone.topics)

var my_coursesClone = [...proxyToObj(my_courses)]
console.log("clone ", my_coursesClone)
my_coursesClone.map((item,index)=>{


  if(item.course === courseClone._id){
  console.log("actualization my_courses")
    item.lessons = [...item.lessons,params.lesson_data ] 

    my_coursesClone[index].lessons = [...item.lessons,params.lesson_data ] ;
  //  my_coursesClone.push()

  }


})


console.log("Mclone ",my_coursesClone)

store.dispatch(setMicoursesUserData(my_coursesClone))
set_my_courses(my_coursesClone) 
setCourse(courseClone)


}

const fetch_course = async () => {
  try {

// console.log("llamando datos")
const endpoint = '/api/v1/course/'+props.courseid
await axios.get(endpoint).then((response) => {
//  setCourses(response.data)




const courseData = response.data

// console.log("Datos curso ",courseData)
  
setCourse(courseData);
settopics(courseData.topics)
store.dispatch(setCourseData(courseData))
// store.dispatch(setActiveLesson({}))
//iniciar en la leccion actual almacenar estado



}) 
} catch (e) {
//console.log(e);
setCourse({title: "", isFetching: false});

}
}

 useEffect(() => {
   
   

fetch_course();

  }, [])

  return (
    
   


   <div className="courseView">
       <div className="row">
          <div className="col-md-8 col-sm-8 col-xs-12 visor"> 
			
          {coursed ? ( 
		
      <LessonView setAutoplay={setautoplay} autoplay_state={autoplay} my_courses={my_courses} course={coursed} user={user} reload={reload_m}/>
     
			
      ):(<></>)}
    
    
      <BrowserView>

     <div className="container comments">
       <br/>
       {coursed ? ( 
		
       <CommentsLessons/>
       ):(<></>)}
    
       
       </div> 
    
     </BrowserView>


		   </div>
           <div className="col-md-4 col-sm-4 col-xs-12">
				<div className="sidebar">
					{/* <h2 className="courseTitle">Course content</h2> 
					<button className="btn btn-trasparent" title="Hide nav contents"><i className="fa fa-close"></i></button>
					<div className="clearfix"></div>*/}
					<div className="topics_panel">
           
					<TopicsPanel setAutoplay={setautoplay} my_courses={my_courses}  user={user} course={coursed}/>
					
         
          </div>
 
          <br/>
      <MobileView>
     <div className="container comments">
     {coursed ? ( 
		
       <CommentsLessons/>
       ):(<></>)}
       
       
       </div> 
     
     </MobileView>

				</div>
           </div>
       </div>
       
   </div>
  )
}


export default CourseContentG;