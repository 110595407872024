import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { useForm, ErrorMessage } from 'react-hook-form'
import './CategoriesAdmin.scss'
const ld = require('lodash');

const columns = [
  {
    name: 'Title',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Slug',
    selector: 'slug',
    sortable: true,
    right: true,
  },
];

export default () => {

  const { register, errors, handleSubmit ,watch,setValue} = useForm()
  const [success, setSuccess] = useState(null)
  const [list_categories, setCategoryList] = useState([])

  const [list_categories_parents, setParentsList] = useState([])

  useEffect(() => {
    const endpoint = '/api/v1/categories_all'
    axios.get(endpoint).then((response) => {
      setCategoryList(response.data)

      let cparents = ld.filter(response.data, { parent: "0" });

      setParentsList(cparents)

    })
  }, [])

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
    setValue('title',state.selectedRows[0].title)

  };


  const onSubmit = (data,e) => {

        axios
          .post('/api/v1/categories', { ...data, confirm_success_url: '/' })
          .then((response) => {
            if (response.data.result === 'error') {
              setSuccess(false)
          
            } else {
              setSuccess(true)
              e.target.reset()
              setCategoryList([... list_categories, data])
              setTimeout(function(){
              let cparents = ld.filter([... list_categories, data], { parent: "0" });
              console.log("new parents ",cparents)
             
              setParentsList(cparents)
                },1000)
            }
          })
          .catch((error) => {
            if (error.response) {
              //console.log(error.response.data)
            }
          })
      }

 return(
   <div className="row">
      <div className="col-md-6 col-xs-12"></div>
      
      <div className="col-md-6 col-xs-12">
      <div className="cont">
          <label>Search</label>

            <input className="form-control"/>
            <button className="btn btn-sm btn-default"><i className="fa fa-search"> </i></button>
      
      </div>
        
        </div>

      <div className="col-md-6 col-xs-12">
        <div className="cont">

          <form onSubmit={handleSubmit(onSubmit)}> 
          <h4>Add Category</h4>


            <div className="panel-form">

            <div className="form-group">
              <label>Category Name:</label>
              <input 
              type="text"
              className="form-control"
              ref={register({
                required:"Is required"
              })}
              name="title"
              />
            </div>

            <div className="form-group">
              <label>Category Slug:</label>
              <input 
              type="text"
              className="form-control"
              ref={register()}
              name="slug"
              />
            </div>

            
            <div className="form-group">
              <label>Parent Category:</label>
              
            
            <select 
            className="form-control"
            ref={register()}
            name="parent"
            >
              
                <option value="0">None</option>

              {list_categories_parents && list_categories_parents.map((item,index) =>{

                return(


                    <option key={index} value={item._id}>{item.title}</option>

                )


              })}

              </select>

              </div>

              
            <div className="form-group">
              <label>Description:</label>
              <input 
              type="text"
              className="form-control"
              ref={register()}
              name="description"
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-default">Save</button>
            </div>


            </div>
</form>

        </div>


      </div>
      
      <div className="col-md-6 col-xs-12">
      <DataTable
        selectableRows
        title="List Categories"
        columns={columns}
        data={list_categories}
        onSelectedRowsChange={handleChange}
      />
      </div>
   </div>
 )
}
