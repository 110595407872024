import React from 'react'
import '../../styles/css/jquery-steps.css'

const steps = [
  {
    name: 'General Information'
  },
  {
    name: 'Preview Image & Video'
  },
  {
    name: 'Course Content'
  },
  {
    name: 'Course Preview'
  }
]

const getStatus = (index, step) => {
  if (index === step) {
    return 'active'
  } else if (index < step) {
    return 'done'
  } else {
    return ''
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ step }) => {
  return (
    <div className="col-12">
      <div className="course_tabs_1">
        <div id="add-course-tab" className="step-app">
          <ul className="step-steps">
            {steps.map(({ name }, index) => {
              return (
                <li key={index} className={getStatus(index, step)}>
                  <a href={`#tab_step${index}`}>
                    <span className="number"></span>
                    <span className="step-name">{name}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
