import React, { Component,setState, useState, useEffect} from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import {Dropdown} from 'semantic-ui-react'
import { store,setActiveLesson } from '../../reducers/index'
import { useStore, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import './topicsPanel.scss'

const ld = require('lodash');



// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
//console.log("props ",props)
 const state = { activeIndex: 0,
    active_session:{},
    course_load:{} 
 }

  //obtendo store
 const lesson_active = useSelector((store) => store.openLesson)  

 const course_actual = props.course  

const [course_enrrolled, setEnrolled] = useState({})



const is_complete = (id_lesson) => {


  const enrrol = ld.filter(props.my_courses, { course: course_actual._id } );

 console.log("enrroll topics ",enrrol)
 
let tmpenr = enrrol[0]

  if(enrrol.length > 0){
 const result_d = ld.filter(tmpenr.lessons,{_id:id_lesson} )
console.log("l completed  ",result_d)


  if(result_d.length === 0){ 
    return false
  }else{
    return true
  }

  }else{
    return false
  }

}


const topic_is_complete = (topic,id_lesson) => {

  const enrrol = ld.filter(props.my_courses, { course: course_actual._id } );
console.log('Cenrrol',enrrol)
  var nro_completed = 0
  
let tmpenr = enrrol[0]

  if(enrrol.length > 0){

    topic.lessons.map((lessT,indexl)=>{

 const result_d = ld.filter(tmpenr.lessons,{_id:lessT._id} )

//console.log("resd cpm have "+lessT._id+' - ',result_d)

  if(result_d.length > 0){ 
    nro_completed ++
  }



})

  //console.log("tmpleg ",nro_completed)

  if(nro_completed === topic.lessons.length){
    return true
  }else{
    return false
  }

  }else{
    return false
  }

}


 //console.log("TP LA ",lesson_active)

 const handleClick = (e, titleProps) => {
  const { index } = titleProps
  const { activeIndex } = state
  const newIndex = activeIndex === index ? -1 : index

  setState({ activeIndex: newIndex })
  setState({topics:props.topics})
  setState({actual_lesson:0})
}

const openLesson = (id_lesson) => {
  //e.preventDefault()
  
  //console.clear()
  //console.log("id lesson ",id_lesson)

 

  let topics_clone = props.course.topics;

  let lesson = props.course.topics.map((item, index) => {
   
    const picked = ld.filter(item.lessons, { _id: id_lesson } );
    picked.topic = index;
    if(picked.length > 0){  
   
    store.dispatch(setActiveLesson(picked[0]))

    props.setAutoplay(false)
    

    }
  })
 
   
   
}


return (
  
  <div className="topic_container">
  <Accordion fluid>
  
  {props.course.topics &&
        props.course.topics.map((item, index) => {

//console.log("item x ",item.lessons)

            return (
              <div key={index}>

          <Accordion.Title
      index={index}
      active={state.activeIndex === 0}
      onClick={handleClick}
    >

    {/* <Icon name='dropdown' /> */}
    <small><Icon name='bookmark' /> This topic have {item.lessons.length} Lessons</small>
    <div className="module_header">
    
    <p><b>{item.title}</b></p>

    <small><Icon name='book' /> {(index + 1)}/{item.lessons.length}</small> <small><Icon name='file image outline' /> 1</small>
    </div>
      </Accordion.Title>

      <Accordion.Content active={state.activeIndex === 0}>
              
      <div className="content_module">
       

        {/* <div className="row-fluid topic_module viewved">
          <span className="icon_topic"><Icon name='video'/></span>
          <span>Otro titulo </span><span className="rightx"><span className="dot topic_actual"></span></span>
        </div>

        <div className="row-fluid topic_module no_viewved">
          <span className="icon_topic"><Icon name='video'/></span>
          <span>Programing </span><span className="rightx"><span className="dot"></span></span>
        </div> */}
        {item.lessons &&
        item.lessons.map((lesson_item, indexp) => {

       //     console.log("litem ",lesson_item)

          let lesson_status = ''

            let cssActive = false
            if(lesson_active._id ===  lesson_item._id){
              cssActive = true;
            }else{
              cssActive = false;  
            }

            if(is_complete(lesson_item._id)){
              lesson_status = 'lesson_completed'
              console.log("Ls: "+lesson_status)
           
            }else{
              lesson_status = ''
            
            
              
            }


         //   console.log("sht ",indexp +'==='+ (item.lessons.length -1))
            
          return (
            <div key={indexp}>
            <div  className={'row-fluid topic_module viewved '+(cssActive ? "lesson_playing" : "")} >
            <a onClick={openLesson.bind(this, lesson_item._id)}>

              <span className="icon_topic"><Icon name='video' /></span>
            <span>{lesson_item.title}</span><span className="rightx"><span className={'dot '+(cssActive ? "lesson_actual" : lesson_status)}></span></span>
            </a>
          </div>
          {topic_is_complete(item) && indexp ===(item.lessons.length -1) ? (
        
          <div className={'row-fluid topic_module'} >
           <Link className="btn btn-sm btn-toolbar" to={'/dashboard/course/evaluate/?topic='+item._id+'&course='+props.course._id}><Icon name='check' /> Evaluate Topic</Link>
            </div>
          ):(<></>)}
          
          
          </div>
           )
      })}
       

      </div>

      </Accordion.Content>
            </div>
         ) })}

  </Accordion>
</div>
)

}



class TopicsPanel extends React.Component {
 
  // eslint-disable-next-line no-useless-constructor
  constructor(props){
    super(props);

    this.state = { activeIndex: 0,
                   active_session:{},
                   course_load:{} 
                }


                this.state.active_session =  this.props.useSelector((store) => store.openLesson)   
                this.state.course_load = this.props.useSelector((store) => store.courseL)               

  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
    this.setState({topics:this.props.topics})
  }

  openLesson = (id_lesson) => {
    //e.preventDefault()
    //console.log("id lesson ",id_lesson)

    let lesson = this.props.topics.map((item, index) => {

      
      const picked = ld.filter(item.lessons, { _id: id_lesson } );
    
      if(picked.length > 0){  
      //console.log("found lesson ",picked)

      
      store.dispatch(setActiveLesson(picked[0]))

      

      }
    })
   
     
     
  }

  render() {
    const { activeIndex, topics } = this.state
    //console.log("ttp ",this.props.topics)

    

    return (
      <div className="topic_container">
      <Accordion fluid>
      
      {this.props.topics &&
            this.props.topics.map((item, index) => {

//console.log("item x ",item.lessons)

                return (
                  <div key={index}>

              <Accordion.Title
          index={index}
          active={activeIndex === 0}
          onClick={this.handleClick}
        >

        {/* <Icon name='dropdown' /> */}
        <small><Icon name='bookmark' /> This topic have {item.lessons.length} Lessons</small>
        <div className="module_header">
        
        <p><b>{item.title}</b></p>

        <small><Icon name='book' /> 1/5</small> <small><Icon name='file image outline' /> 1</small>
        </div>
          </Accordion.Title>

          <Accordion.Content active={activeIndex === 0}>
                  
          <div className="content_module">
           

            {/* <div className="row-fluid topic_module viewved">
              <span className="icon_topic"><Icon name='video'/></span>
              <span>Otro titulo </span><span className="rightx"><span className="dot topic_actual"></span></span>
            </div>

            <div className="row-fluid topic_module no_viewved">
              <span className="icon_topic"><Icon name='video'/></span>
              <span>Programing </span><span className="rightx"><span className="dot"></span></span>
            </div> */}
            {item.lessons &&
            item.lessons.map((lesson_item, index) => {
//console.log("sk ",lesson_item)

              return (
                
                <div key={index} className={'row-fluid topic_module viewved lesson_playing ${active ? "active" : ""}'} >
                <a onClick={this.openLesson.bind(this, lesson_item._id)}>

                  <span className="icon_topic"><Icon name='video' /></span>
                <span>{lesson_item.title}</span><span className="rightx"><span className={'dot '+lesson_item.status}></span></span>
                </a>
              </div>
               )
          })}
           

          </div>

          </Accordion.Content>
                </div>
             ) })}

      </Accordion>
    </div>)
  }
}