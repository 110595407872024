import React, { Component,setState } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import {Dropdown} from 'semantic-ui-react'
import { store,setActiveLesson } from '../../reducers/index'
import { useStore, useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import './topicsPanel.L.scss'

const ld = require('lodash');



// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
console.log("tpsl ",props)
 const state = { activeIndex: 0,
    active_session:{},
    course_load:{} 
 }



return (
  
  <div className="topic_containerL">
  <Accordion fluid>
  
  {props.topics &&
        props.topics.map((item, index) => { 

//console.log("item x ",item.lessons)

            return (
              <div key={index}>

          <Accordion.Title
      index={index}
      active={state.activeIndex === 0}
    >

    {/* <Icon name='dropdown' /> */}
    
    <div className="module_header">
    
    <p><b>{item.title}</b></p>

  
    </div>
      </Accordion.Title>

      <Accordion.Content active={state.activeIndex === 0}>
              
      <div className="">
       <br/>

        {/* <div className="row-fluid topic_module viewved">
          <span className="icon_topic"><Icon name='video'/></span>
          <span>Otro titulo </span><span className="rightx"><span className="dot topic_actual"></span></span>
        </div>

        <div className="row-fluid topic_module no_viewved">
          <span className="icon_topic"><Icon name='video'/></span>
          <span>Programing </span><span className="rightx"><span className="dot"></span></span>
        </div> */}
        {item.lessons &&
        item.lessons.map((lesson_item, index) => {

           

          return (
            
            <div key={index} className='card row-fluid' >
                
                <div className="col-md-8 col-sm-8 col-xs-12"><span className="icon_topic"><Icon name='video' /></span>
            <span>{lesson_item.title}</span></div>

                <div className="col-md-4 col-sm-4 col-xs-12 ">
                  <Icon name='clock' /> {lesson_item.duration}</div>
          
          </div>
           )
      })}
       

      </div>

      </Accordion.Content>
            </div>
         ) })}

  </Accordion>
</div>
)

}

