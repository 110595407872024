import React, { useState, useEffect } from 'react'
import CourseCarousel from './CourseCarousel'
import ProfileCard from './ProfileCard'
import { useSelector } from 'react-redux'
import axios from 'axios'
import {ProgressBar} from 'react-bootstrap'
import {Link} from 'react-router-dom'

import './DashboardNew.scss'

const ld = require('lodash');

export const StudentDashboard = ({ profile, userData }) => {
  const translate = useSelector((state) => state.translate)
  const [courses, setCourses] = useState([])
  const [my_courses_data_load, setMyCourse] = useState([])

  
 const my_courses_data = useSelector((store) => store.user.my_courses)   


 
  useEffect(() => {
    const endpoint = '/api/v1/course'
    axios.get(endpoint).then((response) => {
      setCourses(response.data)
      setMyCourse(my_courses_data)

 
    })
  }, [])

  return (
    <div className="row dashboardStudent">

        <div className="container-fluid">
        <div className="page-section">
        <h1 className="text-display-1 margin-none">Overview</h1>
        </div>

        {/* <div className="row"> 
        <div className="media media_df">
  <div className="media-icon"><i className="fa fa-credit-card fa-fw fa-2x"></i></div>
  <div className="media-body">
                Your subscription ends on <span className="text-body-2">25 February 2015</span>
              </div>
</div>

        </div> */}

       <div className="row">
       <CourseCarousel
            key="0"
            courses={courses.filter((item) => item.mandatory)} 
            sectionTitle={
              <div>
                <i className="fa fa-check"></i>{' '}
                {translate.DASHBOARD.STUDENT.MANDATORY_COURSES}
              </div>
            }
          />
        
         
       </div>


        <div className="row">
                <div className="item col-xs-12 col-lg-6">
{my_courses_data_load.length ? (
                <div className="card">
  
<div className="panel-heading">
                <h4 className="text-headline">My Courses</h4>
       <p className="text-light2">Courses you enrrolled.</p>
</div>
  <div className="card-body">

<br/><br/>
       <ul className="list-group lgot w-100">

{my_courses_data_load && my_courses_data_load.map((item, index) => {
 
 let course_data_item = ld.filter(courses, { _id: item.course })[0];  
              
           console.log("csd ",course_data_item)

 return(
 <li className="list-group-item  "><Link to={'/dashboard/course/?courseid='+course_data_item._id}>{course_data_item && course_data_item.title}
  <ProgressBar now={70} /></Link>
                      </li>
 )

})}
   

</ul>


  </div>
</div>

):(<></>)}


<div className="forum container">
<h4 className="text-headline margin-none">Forum Activity</h4>
<p className="text-subhead text-light2">Latest forum topics &amp; comments</p>


<ul className="list-group relative paper-shadow" data-hover-z="0.5" data-animated="">
                <li className="list-group-item paper-shadow">
                  <div className="media v-middle">
                    <div className="media-left">
                      <a href="#">
                        <img src="images/guy-3.jpg" alt="person" className="imst img-circle width-40"/>
                      </a>
                    </div>
                    <div className="media-body">
                      <a ui-sref="app-forum.thread" className="text-subhead link-text-color" href="#/app-forum/thread">Can someone help me with AngularJS?</a>
                      <div className="text-light2">
                        Topic: <a ui-sref="app-forum.category" href="#/app-forum/category">AngularJS</a> &nbsp; By: <a href="#">Adrian Demian</a>
                      </div>
                    </div>
                    <div className="media-right">
                      <div className="width-60 text-right">
                        <span className="text-caption text-light2">1 hr ago</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item paper-shadow">
                  <div className="media v-middle">
                    <div className="media-left">
                      <a href="#">
                        <img src="/images/guy-3.jpg" alt="person" className="imst img-circle width-40"/>
                      </a>
                    </div>
                    <div className="media-body">
                      <a ui-sref="app-forum.thread" className="text-subhead link-text-color" href="#/app-forum/thread">Can someone help me with AngularJS?</a>
                      <div className="text-light2">
                        Topic: <a ui-sref="app-forum.category" href="#/app-forum/category">AngularJS</a> &nbsp; By: <a href="#">Adrian Demian</a>
                      </div>
                    </div>
                    <div className="media-right">
                      <div className="width-60 text-right">
                        <span className="text-caption text-light2">2 hrs ago</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item paper-shadow">
                  <div className="media v-middle">
                    <div className="media-left">
                      <a href="#">
                        <img src="/images/guy-3.jpg" alt="person" className="imst img-circle width-40"/>
                      </a>
                    </div>
                    <div className="media-body">
                      <a ui-sref="app-forum.thread" className="text-subhead link-text-color" href="#/app-forum/thread">Can someone help me with AngularJS?</a>
                      <div className="text-light2">
                        Topic: <a ui-sref="app-forum.category" href="#/app-forum/category">AngularJS</a> &nbsp; By: <a href="#">Adrian Demian</a>
                      </div>
                    </div>
                    <div className="media-right">
                      <div className="width-60 text-right">
                        <span className="text-caption text-light2">3 hr ago</span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="list-group-item paper-shadow">
                  <div className="media v-middle">
                    <div className="media-left">
                      <a href="#">
                        <img src="/images/guy-3.jpg" alt="person" className="imst img-circle width-40"/>
                      </a>
                    </div>
                    <div className="media-body">
                      <a ui-sref="app-forum.thread" className="text-subhead link-text-color" href="#/app-forum/thread">Can someone help me with AngularJS?</a>
                      <div className="text-light2">
                        Topic: <a ui-sref="app-forum.category" href="#/app-forum/category">AngularJS</a> &nbsp; By: <a href="#">Adrian Demian</a>
                      </div>
                    </div>
                    <div className="media-right">
                      <div className="width-60 text-right">
                        <span className="text-caption text-light2">4 hr ago</span>
                      </div>
                    </div>
                  </div>

                </li>
              </ul>

</div>
<br/><br/>



                </div>

                {/* <div className="item col-xs-12 col-lg-6">

                <div className="panel panel-default paper-shadow" data-z="0.5">
                <div className="panel-body">
                  <h4 className="text-headline margin-none">Rewards</h4>
                  <p className="text-subhead text-light22">Your latest achievements</p>
                  <div className="icon-block half img-circle bg-purple-300">
                    <i className="fa fa-star text-white"></i>
                  </div>
                  <div className="icon-block half img-circle bg-indigo-300">
                    <i className="fa fa-trophy text-white"></i>
                  </div>
                  <div className="icon-block half img-circle bg-green-300">
                    <i className="fa fa-mortar-board text-white"></i>
                  </div>
                  <div className="icon-block half img-circle bg-orange-300">
                    <i className="fa fa-code-fork text-white"></i>
                  </div>
                  <div className="icon-block half img-circle bg-red-300">
                    <i className="fa fa-diamond text-white"></i>
                  </div>
                </div>
              </div>


              <div className="panel rewardsp panel-default paper-shadow" data-z="0.5">
                <div className="panel-heading">
                  <h4 className="text-headline">Certificates
                    <small>(4)</small>
                  </h4>
                </div>
                <div className="panel-body">
                  <a className="btn btn-default text-grey-400 btn-lg btn-circle paper-shadow relative" data-hover-z="0.5" data-animated="" data-toggle="tooltip" data-title="Name of Certificate">
                    <i className="fa fa-file-text"></i>
                  </a>
                  <a className="btn btn-default text-grey-400 btn-lg btn-circle paper-shadow relative" data-hover-z="0.5" data-animated="" data-toggle="tooltip" data-title="Name of Certificate">
                    <i className="fa fa-file-text"></i>
                  </a>
                  <a className="btn btn-default text-grey-400 btn-lg btn-circle paper-shadow relative" data-hover-z="0.5" data-animated="" data-toggle="tooltip" data-title="Name of Certificate" data-original-title="" title="">
                    <i className="fa fa-file-text"></i>
                  </a>
                  <a className="btn btn-default text-grey-400 btn-lg btn-circle paper-shadow relative" data-hover-z="0.5" data-animated="" data-toggle="tooltip" data-title="Name of Certificate" data-original-title="" title="">
                    <i className="fa fa-file-text"></i>
                  </a>
                </div>
              </div>

              <br/><br/>
              <div class="panel panel-default paper-shadow" data-z="0.5">
                <div class="panel-heading">
                  <h4 class="text-headline margin-none">Quizzes</h4>
                  <p class="text-subhead text-light2">Your recent performance</p>
                </div>
                <ul class="list-group">
                  <li class="list-group-item media v-middle">
                    <div class="media-body">
                      <h4 class="text-subhead margin-none">
                        <a ui-sref="app-student.take-quiz" class="list-group-link" href="#/app-student/take-quiz">Title of quiz goes here?</a>
                      </h4>
                      <div class="caption">
                        <span class="text-light">Course:</span>
                        <a ui-sref="app-student.take-course" href="#/app-student/take-course">Basics of HTML</a>
                      </div>
                    </div>
                    <div class="media-right text-center">
                      <div class="text-display-1">5.8</div>
                      <span class="caption text-light">Good</span>
                    </div>
                  </li>
                  <li class="list-group-item media v-middle">
                    <div class="media-body">
                      <h4 class="text-subhead margin-none">
                        <a ui-sref="app-student.take-quiz" class="list-group-link" href="#/app-student/take-quiz">Directives &amp; Routing</a>
                      </h4>
                      <div class="caption">
                        <span class="text-light">Course:</span>
                        <a ui-sref="app-student.take-course" href="#/app-student/take-course">Angular in Steps</a>
                      </div>
                    </div>
                    <div class="media-right text-center">
                      <div class="text-display-1 text-green-300">9.8</div>
                      <span class="caption text-light">Great</span>
                    </div>
                  </li>
                  <li class="list-group-item media v-middle">
                    <div class="media-body">
                      <h4 class="text-subhead margin-none">
                        <a ui-sref="app-student.take-quiz" class="list-group-link" href="#/app-student/take-quiz">Responsive &amp; Retina</a>
                      </h4>
                      <div class="caption">
                        <span class="text-light">Course:</span>
                        <a ui-sref="app-student.take-course" href="#/app-student/take-course">Bootstrap Foundations</a>
                      </div>
                    </div>
                    <div class="media-right text-center">
                      <div class="text-display-1 text-red-300">3.4</div>
                      <span class="caption text-light">Failed</span>
                    </div>
                  </li>
                </ul>
                <div class="panel-footer">
                  <a ui-sref="app-student.quizzes" class="btn btn-primary paper-shadow relative" data-z="0" data-hover-z="1" data-animated="" href="#"> Go to Results</a>
                </div>
              </div>

              <br/><br/>
                </div>
 */}

        </div>

       

        </div>
      {/* {courses && (
        <div className="col-xl-12 col-lg-12">
          <CourseCarousel
            key="0"
            courses={courses.filter((item) => item.mandatory)}
            sectionTitle={
              <div>
                <i className="fa fa-check"></i>{' '}
                {translate.DASHBOARD.STUDENT.MANDATORY_COURSES}
              </div>
            }
          />
          <CourseCarousel
            key="1"
            courses={courses.filter((item) => !item.mandatory)}
            sectionTitle={
              <div>
                <i className="fa fa-star"></i>{' '}
                {translate.DASHBOARD.STUDENT.OPTIONAL_COURSES}
              </div>
            }
          />
        </div>
      )} */}
      

      
    </div>
  )
}
