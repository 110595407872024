import React,{useState,useEffect} from 'react'
import axios from 'axios'
import LessonForm from './LessonForm'
import { useForm, ErrorMessage } from 'react-hook-form'
import { useSelector } from 'react-redux'

import './TopicForm.scss'

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ courseId, nextStep,position,updateTopicListF }) => {
  const { register, errors, handleSubmit, setValue } = useForm()
  const translate = useSelector((state) => state.translate)


  const [lessons_topic ,setLesson] = useState({topic_form:position,lessons:[{}]})

  const onSubmit = (data) => {
    //console.log('course form data', data)
    const endpoint = `/api/v1/course/`
    axios.post(endpoint, data).then((response) => {
      nextStep()
    })
  }


  useEffect(() => {
    

    window.jQuery(function ($) {
     

    })

  })


  const lesson_add = ((data) => {


    window.jQuery(function ($) {

      $('.form_lesson').submit((e)=>{

        e.preventDefault()
      console.log("todo ok")

      let form = $(this)
        
       console.log(form[0])
  
  
      })


      const array_less = $($('.form_lesson').last()).serializeArray();

      const form_obj_last = {
        title:array_less[0].value, 
        duration:array_less[1].value, 
        lesson_type:array_less[2].value, 
        video_url:array_less[3].value,
        lesson_description:array_less[4].value
      }

      console.log(form_obj_last)


     // $('.form_lesson').last().submit()

   setLesson({topic_form:position,lessons:[...lessons_topic.lessons, form_obj_last ]})


   
   updateTopicListF({topic_form:position,lessons:[...lessons_topic.lessons, form_obj_last ]})

   console.log("trf ",lessons_topic)

  

    })
  })

  const lessons_out = ((data)=>{
   
    setLesson([...lessons_topic, data])

  })

  return (
    <div className="topic-form">
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)} className="topic-form_f">
        <div className="course__form shadow-white">
          <div className="general_info10">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title pb-20 dark">
                  <i className="fa fa-bookmark" />
                  {translate.TOPIC.NEW_TOPIC}
                </h2>
                <hr className="" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ui search focus mt-30 lbel25">
                  <label>{translate.TOPIC.TITLE}*</label>
                  <div className="ui input swdh19">
                    <input
                      className="prompt"
                      type="text"
                      placeholder={translate.GENERAL.INSERT_TITLE}
                      name="title"
                      maxLength="60"
                      id="title"
                      ref={register({
                        required: translate.GENERAL.REQUIRED
                      })}
                    />
                    <div className="badge_num">60</div>
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" />
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-12">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h2 className="title">{translate.TOPIC.LECTURES}</h2>

                <div className="lesson_container">
                {lessons_topic && lessons_topic.lessons.map((item, index) => {
        
          return <LessonForm keyF={index} key={index} lessons_g={lessons_out}/>
       })}


                </div>
                <div className="mt-30 add-lecture-btn px-20 text-center" onClick={lesson_add}>
                  <h3 className="dark">
                    <i className="fa fa-book" /> {translate.TOPIC.ADD_NEW}
                  </h3>
                </div>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-12">
                <hr />
              </div>
            </div>
            <div className="row">
              <div className="col-md-2">
                <div className="ui search focus mt-30 lbel25">
                  <label>{translate.TOPIC.POSITION}</label>
                  <div className="ui input swdh19 mt-10">
                    <input
                      className="prompt"
                      type="text"
                      placeholder={1}
                      name="position"
                      maxLength="60"
                      id="position"
                      ref={register({
                        required: translate.GENERAL.REQUIRED
                      })}
                    />
                    <div className="badge_num">Place</div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="ui search focus mt-30 lbel25 text-center">
                  <label className="text-center">
                    {translate.TOPIC.LECTURES}
                  </label>
                  <h1 className="dark">{lessons_topic.length}</h1>
                </div>
              </div>
              <div className="col-md-2">
                <div className="ui search focus mt-30 lbel25 text-center">
                  <label className="text-center">
                    {translate.TOPIC.DURATION}
                  </label>
                  <h1 className="dark">00:00:00</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
