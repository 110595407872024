export const translate = {
  GENERAL: {
    WELCOME: 'Welcome to MASAMO',
    REQUIRED: 'This field is required.',
    MIN_LENGTH: 'Use {x} characters or more for {field}',
    FORGOT_PASSWORD: 'Forgot password',
    OR: 'o',
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    HAVE_ACCOUNT: 'Already have an Account?',
    SIGN_IN: 'Sign In',
    SIGN_UP: 'Sign Up',
    INVALID_EMAIL: 'Invalid Email Address',
    GO_BACK: 'Go Back',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    EMAIL: 'Email Address',
    FULL_NAME: 'Full Name',
    SELECT_VALUE: 'Select value',
    CREATE_COURSE: 'Create New Course',
    DASHBOARD: 'Dashboard',
    SIGN_OUT: 'Sign Out',
    VIEW: 'View',
    ENROLL: 'Enroll',
    LEARN_MORE: 'Learn More',
    OPTIONAL_COURSES: 'Optional Courses',
    MANDATORY_COURSERS: 'Mandatory Courses',
    GO_PROFILE: 'Go to Profile',
    SEE_ALL: 'See all',
    USER_LIST: 'User List',
    INSERT_TITLE: 'Insert Title',
    COURSE_CONTENT: 'Course Content',
    ADD_NEW_TOPIC: 'Add New topic'
  },
  TOPIC: {
    TITLE: 'Topic Title',
    NEW_TOPIC: 'New Topic',
    LECTURES: 'Topic Lessons',
    POSITION: 'Topic Position',
    DURATION: 'Topic Duration',
    ADD_NEW: 'Add New Lesson'
  },
  LOGIN: {
    NO_APPROVE: 'Your account hasn\t been aproved',
    LOGIN_ACCOUNT: 'Log In to your Account!'
  },
  FORGOT_PASSWORD: {
    TITLE: 'Request a Password Reset',
    RESET_PASSWORD: 'Reset Password'
  },
  RESET_PASSWORD: {
    TITLE: 'Reset Your Password',
    PLACEHOLDER_PASS: 'Write new password',
    SUCCESS: 'Your Password has been succesfully reseted'
  },
  REGISTER: {
    TITLE: 'Sign Up & Start Learning!',
    SUCCESS:
      'Thanks! Your information has been submitted. You will get an email notification once your account gets approved.',
    TERMS_1: 'By signing up, you agree to our',
    TERMS_2: 'Terms of Use & Privacy Policy',
    ACTIVATE_ACCOUNT1: 'Activate Account!',
    ACTIVATE_ACCOUNT_SUCCESS: 'Thanks! Your account has been approved.',
    ACTIVATE_ACCOUNT_ERROR: 'Sorry! Your account has not approved.',
  },
  DASHBOARD: {
    COMPANY: {
      TITLE: 'Company Dashboard',
      JUMP_COURSE: 'Jump Into Course Creation',
      LATEST_COURSE_PERFORMANCE: 'Latest Course Performance',
      NEWS: 'News',
      PROFILE_ANALYTICS: 'Profile Analytics',
      CURRENT_SUBSCRIBERS: 'Current subscribers',
      GO_TO_ANALYTICS: 'Go to profile analytics'
    },
    STUDENT: {
      MANDATORY_COURSES: 'Mandatory Courses',
      OPTIONAL_COURSES: 'Optional Courses'
    }
  },
  INDICATORS: {
    TOTAL_ENROLL: 'Total Enrol',
    TOTAL_COURSES: 'Total Courses',
    TOTAL_STUDENTS: 'Total Students'
  },
  MAIN_MENU: {
    HOME: 'Home',
    COURSES: 'Courses',
    CATEGORIES: 'Categories',
    MYCOURSES: 'My Courses',
    NOTIFICATIONS: 'Notifications',
    ANALYTICS: 'Analytics',
    CREATE_COURSE: 'Create Course',
    EVALUATIONS: 'Evaluations',
    ADD_EVALUATIONS: 'Add Evaluations',
    USERS: 'Users',
    SETTINGS: 'Settings',
    SEND_FEEDBACK: 'Send Feedback'
  },
  MEDIA: {
    PREVIEW: 'Preview Image & Video',
    IMG_TITLE: 'Cover Image',
    IMG_DESC:
      'Upload your course image here. It must meet our course image quality standards to be accepted. Important guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no text on the image.',
    VIDEO_TITLE: 'Promotional Video',
    VIDEO_DESC:
      "Students who watch a well-made promo video are 5X more likely to enroll in your course. We've seen that statistic go up to 10X for exceptionally awesome videos. Learn how to make yours awesome!"
  }
}
