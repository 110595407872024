import React from 'react'
import ActivateAct from '../components/auth/ActivateAccount'
import AuthLayout from '../components/layout/AuthLayout'

export const ActivateAccount = () => {
  return (
    <AuthLayout>
      <ActivateAct/>
    </AuthLayout>
  )
}
