import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import ListStudent from './Course/CourseListStudent'
import { useStore, useSelector } from 'react-redux'


export const CourseListStudent = () => {

  
  const userData = useSelector((store) => store.user)
  console.log("listmy  ",userData)

  return (
    <DashboardLayout>
      <ListStudent />
    </DashboardLayout>
  )
}
