import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import UserList from './Company/UserList'
import { useSelector } from 'react-redux'

export const Users = () => {
  const translate = useSelector((state) => state.translate)
  return (
    <DashboardLayout>
      <div>
        <h2>{translate.GENERAL.USER_LIST}</h2>
        <UserList />
      </div>
    </DashboardLayout>
  )
}
