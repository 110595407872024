import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import Lists from './Course/CourseLists'

export const CourseLists = () => {
  return (
    <DashboardLayout>
      <Lists />
    </DashboardLayout>
  )
}
