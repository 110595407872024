import React, { useState } from 'react'
import TopicForm from './TopicForm'
import { useSelector } from 'react-redux'
import axios from 'axios'

import './CourseContent.scss'

const CourseContent = ({ course }) => {

  console.log("courseid ",course)


  const [contentList, setContentList] = useState({
    topic: [{}]
  })

const [topics_list, setTopicsList] = useState([{}])

  const translate = useSelector((state) => state.translate)

  const setTopicData = (topicData, index) => {
    const newContent = contentList

    console.log("content antes ", newContent)

    newContent.topic[topicData.topic_form] = topicData


    setTopicsList(newContent)

    console.log("tp en lista add ", contentList)
  }


  const updateTopicList = ((data)=>{
    const newContent = contentList
    
    newContent.topic[data.topic_form] = data

    console.log("nwc ",newContent)
    //setTopicData(data,data.topic_form)

    setTopicsList(newContent)



  })

  const addTopic =  async () => {
    // let topic = contentList.topic

    // console.log("tp",topic)

    // topic.push({})
    // setContentList({ ...contentList, topic })
    let topic_last_form = []

    await window.jQuery( async function ($) {

       topic_last_form = $($('.topic-form_f').last()).serializeArray();
       console.log("tpf:: ",topic_last_form)
    
    
      

    console.log("tpf:: ",topic_last_form)

        let out = []
      topics_list.topic[topics_list.topic.length-1].lessons.map((item, index) => {

        if(Object.keys(item).length > 0 ){  
          out.push(item)
        }
        
      })
    
      topics_list.topic[topics_list.topic.length-1].lessons = out

      console.log("ltp ",topic_last_form)
      console.log("topicsf ",topics_list)

      let topic_item = {title:topic_last_form[0].value,position:topics_list.topic[topics_list.topic.length-1].topic_form,lessons:topics_list.topic[topics_list.topic.length-1].lessons}
        console.log("titem ",topic_item)

      let enconde_json_form = JSON.stringify(topic_item);

      console.log('json ',enconde_json_form)
      axios.defaults.headers.common = { }
      axios.defaults.headers.common = { "Content-Type": "application/json; charset=utf-8"}
      const endpoint = `/api/v1/course/add_topic`
    
     
      var formData = new FormData();
      formData.append('new_topic', enconde_json_form);
      formData.append('courseId', course);

      fetch('https://dev.masamo.net:2022'+endpoint, {
        method: 'POST',
        body: formData
     })


     setContentList({topic:[...contentList.topic,topic_item]}) 
      // await axios.post(endpoint, {new_topic:enconde_json_form,courseId:course}).then((response) => {
      // //  nextStep()
      // })

       
    })
  



  }

  return (
    <div className="tab-from-content course-content">
      <h2 className="title">
        <i className="fa fa-book-open" />
        {translate.GENERAL.COURSE_CONTENT}
      </h2>

      {contentList.topic.map((item, index) => {
        return <TopicForm {...item} key={index} position={index} updateTopicListF={updateTopicList}/>
      })}

      <div className="row">
        <div className="col-md-12">
          <div
            className="mt-30 add-topic-btn px-20 text-center"
            onClick={addTopic}
          >
            <h2>
              <i className="fa fa-bookmark" /> {translate.GENERAL.ADD_NEW_TOPIC}
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseContent
