import axios from 'axios'
import React, { useState } from 'react'
import { useForm, ErrorMessage } from 'react-hook-form'
import { Alert } from 'react-bootstrap'

export default (props) => {
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      name: 'juan',
      ...props.data
    }
  })
  const [profileError, setProfileError] = useState(null)
  const [success, setSuccess] = useState(null)

  //console.log('profile data', props)

  const uploadImage = (e) => {
    const endpoint = `/api/v1/profiles/${props.data.id}/picture`
    const formdata = new FormData()
    const file = document.getElementById('picture').files[0]
    const config = {
      headers: axios.defaults.headers.common
    }

    delete config.headers['content-type']
    formdata.append('picture', file)

    axios
      .post(endpoint, formdata, config)
      .then((response) => {
        //console.log(response.data)
        setSuccess(true)
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        //console.log(error.response)
        setProfileError(error.response.data.errors)
        setProfileError(true)
      })
  }

  const onSubmit = (data) => {
    const endpoint = `/api/v1/profiles/${props.data.id}`

    axios
      .put(endpoint, data)
      .then((response) => {
        //console.log(response)
        setSuccess(true)
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        if (error.response) {
          //console.log(error.response.data)
          setProfileError(error.response.data.errors)
          setProfileError(true)
        }
      })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="account_setting">
        <h4>Your Account</h4>
        <p>This is your public presence on masamo</p>
        {success && (
          <Alert variant="success">Your data was succesfully saved</Alert>
        )}
        {profileError && (
          <Alert variant="danger">{profileError.join(', ')}</Alert>
        )}
        <div className="basic_profile">
          <div className="basic_ptitle">
            <h4>Basic Profile</h4>
            <p>Add information about yourself</p>
          </div>
          <div className="basic_form">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ui focus mt-30">
                      <div className="ui left icon input swdh11 swdh19">
                        <input
                          className="prompt"
                          type="text"
                          name="name"
                          id="name"
                          maxLength="40"
                          ref={register({
                            required: 'This field is required.',
                            maxLength: 40
                          })}
                          placeholder="Full Name"
                        />
                      </div>
                    </div>
                    <div className="error-message">
                      <ErrorMessage as="span" errors={errors} name="email" />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="ui focus mt-30">
                      <div className="ui left icon input swdh11 swdh19">
                        <input
                          className="prompt"
                          type="file"
                          name="picture"
                          id="picture"
                          placeholder="Profile Picture"
                          // onChange={(e) => uploadImage(e)}
                        />
                      </div>
                      <div className="help-block">
                        Upload your profile picture
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="ui focus mt-30">
                      <div className="ui left icon input swdh11 swdh19">
                        <a
                          href="#0"
                          className="btn-block btn-custom btn-custom-primary"
                          onClick={(e) => uploadImage(e)}
                        >
                          Upload
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="ui focus mt-30">
                      <div className="ui left icon input swdh11 swdh19">
                        <input
                          className="prompt"
                          type="text"
                          name="headline"
                          id="headline"
                          ref={register}
                          placeholder="Headline"
                        />
                        <div
                          className="form-control-counter"
                          data-purpose="form-control-counter"
                        >
                          36
                        </div>
                      </div>
                      <div className="help-block">
                        Add a professional headline like, "Engineer at masamo"
                        or "Architect."
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="ui search focus mt-30">
                      <div className="ui form swdh30">
                        <div className="field">
                          <textarea
                            rows="3"
                            name="description"
                            id="description"
                            className="prompt"
                            placeholder="Write a little description about you..."
                          ></textarea>
                        </div>
                      </div>
                      <div className="help-block">
                        Links and coupon codes are not permitted in this
                        section.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="divider-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basic_profile1">
          <div className="basic_ptitle">
            <h4>Profile Links</h4>
          </div>
          <div className="basic_form">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ui search focus mt-30">
                      <div className="ui left icon labeled input swdh11 swdh31">
                        <div className="ui label lb12">
                          http://facebook.com/
                        </div>
                        <input
                          className="prompt srch_explore"
                          type="text"
                          name="facebook"
                          id="facebook"
                          required=""
                          maxLength="64"
                          ref={register}
                          placeholder="Facebook Profile"
                        />
                      </div>
                      <div className="help-block">
                        Add your Facebook username (e.g. johndoe).
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="ui search focus mt-30">
                      <div className="ui left icon labeled input swdh11 swdh31">
                        <div className="ui label lb12">http://twitter.com/</div>
                        <input
                          className="prompt srch_explore"
                          type="text"
                          name="twitter"
                          id="twitter"
                          ref={register}
                          required=""
                          maxLength="64"
                          placeholder="Twitter Profile"
                        />
                      </div>
                      <div className="help-block">
                        Add your Twitter username (e.g. johndoe).
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="ui search focus mt-30">
                      <div className="ui left icon labeled input swdh11 swdh31">
                        <div className="ui label lb12">
                          http://www.linkedin.com/
                        </div>
                        <input
                          className="prompt srch_explore"
                          type="text"
                          name="linkedin"
                          id="linkedin"
                          ref={register}
                          required=""
                          maxLength="64"
                          placeholder="Linkedin Profile"
                        />
                      </div>
                      <div className="help-block">
                        Input your LinkedIn resource id (e.g. in/johndoe).
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="save_btn" type="submit">
          Save Changes
        </button>
      </div>
    </form>
  )
}
