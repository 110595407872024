import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Routes from '../../routes/index'

const AppRouter = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>

      {Routes.all.map((item) => {
        return (
          <Route
            exact
            key={item.name}
            path={item.path}
            component={item.component}
          />
        )
      })}

      {/* {Routes.private.map((item) => {
          return (
            <Route
              key={item.name}
              path={item.path}
              component={item.component}
            />
          )
        })} */}
    </Switch>
  )
}

export default AppRouter
