import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AuthManager from '../../../helpers/AuthManager'

import logo from '../../../images/app-logo.svg'
import logoInverse from '../../../images/ct_logo.svg'
import imageProfileDefault from '../../../images/hd_dp.jpg'

import './Header.scss'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ userData }) => {
  //console.log('----header userdata----', userData)

  let history = useHistory()
  const translate = useSelector((state) => state.translate)

  useEffect(() => {
    window.jQuery(function ($) {
      $('.ui.dropdown').dropdown()

      $("#collapse_menu, #toggleMenu").click(function(){
   
      if($('#menu_lat').css('left') === '0px'){
        $('#menu_lat').css('left', '-240px') 
      
        
        if(!isMobile){
          $('.wrapper').css('margin-left', '0px')
        }


      }else{
        $('#menu_lat').css('left', '0px') 


        
        if(!isMobile){
          $('.wrapper').css('margin-left', '240px')
        }
        
      }
       
     


      })

      


    })
  }, [])

  const logout = () => {
    AuthManager.logout()
    history.push('/login')
  }

  return (
    <header className="header clearfix">
      <button type="button" id="toggleMenu" className="toggle_menu">
        <i className="uil uil-bars"></i>
      </button>

      <button id="collapse_menu" className="collapse_menu">
        <i className="uil uil-bars collapse_menu--icon "></i>
        <span className="collapse_menu--label"></span>
      </button>

      <div className="main_logo" id="logo">
        <a href="index.html">
          <img src={logo} alt="" hieght="30px" />
        </a>
        <a href="index.html">
          <img
            className="logo-inverse"
            height="30px"
            src={logoInverse}
            alt=""
          />
        </a>
      </div>

      <div className="search120">
        <div className="ui search">
          <div className="ui left icon input swdh10">
            <input
              className="prompt srch10 masamo-search"
              type="text"
              placeholder="Type something"
            />
            <i className="uil uil-search-alt icon icon1"></i>
          </div>
        </div>
      </div>

      <div className="header_right">
        <ul>
          <li>
            {userData.roles &&
              userData.roles[0].name.toLowerCase() === 'company' && (
                <Link
                  to="/dashboard/course/new"
                  btn-custom btn-custom-primary
                  className="btn-custom btn-custom-primary"
                >
                  {translate.GENERAL.CREATE_COURSE}
                </Link>
              )}
          </li>
          <li>
            <a href="index.html" className="option_links">
              <i className="fa fa-home fa-3x"></i>
            </a>
          </li>
          <li className="ui dropdown">
            <a href="#0" className="option_links">
              <i className="fa fa-bell"></i>
              <span className="noti_count">3</span>
            </a>
            <div className="menu dropdown_mn">
              <a href="#0" className="channel_my item">
                <div className="profile_link">
                  <img
                    src={
                      userData.profile && userData.profile.image_url.thumb.url
                        ? `${process.env.REACT_APP_API_ENDPOINT}${userData.profile.image_url.thumb.url}`
                        : imageProfileDefault
                    }
                    alt=""
                  />
                  <div className="pd_content">
                    <h6>Rock William</h6>
                    <p>
                      Like Your Comment On Video{' '}
                      <strong>How to create sidebar menu</strong>.
                    </p>
                    <span className="nm_time">2 min ago</span>
                  </div>
                </div>
              </a>
            </div>
          </li>
          <li className="ui dropdown">
            <Link to="/profile" className="opts_account">
              <img
                src={
                  userData.profile && userData.profile.image_url.thumb.url
                    ? `${process.env.REACT_APP_API_ENDPOINT}${userData.profile.image_url.thumb.url}`
                    : imageProfileDefault
                }
                alt=""
              />
            </Link>
            <div className="menu dropdown_account">
              <div className="channel_my">
                <div className="profile_link">
                  <img
                    src={
                      userData.profile && userData.profile.image_url.thumb.url
                        ? `${process.env.REACT_APP_API_ENDPOINT}${userData.profile.image_url.thumb.url}`
                        : imageProfileDefault
                    }
                    alt=""
                  />
                  <div className="pd_content">
                    <div className="rhte85">
                      <h6>{userData.name}</h6>
                      <div className="mef78" title="Verify">
                        <i className="uil uil-check-circle"></i>
                      </div>
                    </div>
                    {userData.email}
                  </div>
                </div>
                <Link to="/profile" className="dp_link_12">
                  Edit Profile
                </Link>
              </div>
              <Link to="/dashboard" className="item channel_item">
                {translate.GENERAL.DASHBOARD}
              </Link>
              <a
                href="#0"
                onClick={(e) => logout(e)}
                className="item channel_item"
              >
                {translate.GENERAL.SIGN_OUT}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </header>
  )
}
