import React from 'react'
import Logo from '../../images/app-logo.svg'
import './AuthLayout.scss'

export default (props) => {
  return (
    <div className="auth-bg">
      <div className="container">
        <div className="row justify-content-lg-center justify-content-md-center">
          <div className="col-lg-12">
            <div className="main_logo25" id="logo">
              <a href="index.html">
                <img src={Logo} alt="" />
              </a>
              <a href="index.html">
                <img className="logo-inverse" src={Logo} alt="" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-8">{props.children}</div>
        </div>
      </div>
    </div>
  )
}
