import axios from 'axios'
import React, { useState,useRef } from 'react'
import AuthManager from '../../helpers/AuthManager'
import { useForm, ErrorMessage } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const { register, errors, handleSubmit ,watch} = useForm()
  const [signupError, setSignUphError] = useState(null)
  const [signupErrorM, setSignUphErrorM] = useState(null)
  const [success, setSuccess] = useState(null)
  const translate = useSelector((state) => state.translate)

  const password = useRef({});
  password.current = watch("password", "");

  let history = useHistory()
  const onChange = (value) => {
    //console.log("Captcha value:", value);
  }

  const onSubmit = (data) => {

//console.log(data);

    axios
      .post('/auth/company', { ...data, confirm_success_url: '/' })
      .then((response) => {
        if (response.data.result === 'error') {
          setSuccess(false)
          setSignUphError(true)
          setSignUphErrorM(response.data.msg)

        } else {
          setSuccess(true)
        
        }
      })
      .catch((error) => {
        if (error.response) {
          //console.log(error.response.data)
          setSignUphError(error.response.data.errors)
        }
      })
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
    }
  }

  return (
    <React.Fragment>
      <div className="sign_form">
        <h2>{translate.GENERAL.WELCOME}</h2>
        <p>Company Sign Up</p>

        {!success ? (
          <div>
            <div className="error-message danger">
              {signupError && signupErrorM}
            </div>

            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                <input 
                ref={register()}
                type="hidden" name="rol" value="student"/>
                  
                  <input
                    className="prompt"
                    type="text"
                    name="name"
                    id="name"
                    maxLength="40"
                    placeholder={translate.GENERAL.FULL_NAME}
                    ref={register({
                      required: translate.GENERAL.REQUIRED,
                      maxLength: 40
                    })}
                  />
                  <i className="uil uil-user icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="name" />
                </div>
              </div>

              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                  <input
                    className="prompt"
                    type="text"
                    name="nickname"
                    id="nickname"
                    maxLength="20"
                    placeholder={translate.GENERAL.USERNAME}
                    ref={register({
                      required: translate.GENERAL.REQUIRED,
                      minLength: {
                        value: 3,
                        message: 'Use 3 characters or more for your username.'
                      }
                    })}
                  />
                  <i className="uil uil-user-circle icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="nickname" />
                </div>
              </div>
              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                  <input
                    className="prompt"
                    type="text"
                    name="email"
                    id="email"
                    maxLength="40"
                    placeholder={translate.GENERAL.EMAIL}
                    ref={register({
                      required: translate.GENERAL.REQUIRED,
                      maxLength: 40,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address"
                      }
                    })}
                  />
                  <i className="uil uil-envelope icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="email" />
                </div>
              </div>

              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                  <input
                    className="prompt"
                    type="password"
                    name="password"
                    id="password"
                    maxLength="20"
                    placeholder="Password"
                    ref={register({
                      required: translate.GENERAL.REQUIRED,
                      maxLength: 20,
                      minLength: {
                        value: 6,
                        message: 'Use 6 characters or more for your password.'
                      }
                    })}
                    onKeyUp={(e) => handleKeyPress(e.target.value)}
                  />
                  <i className="uil uil-key-skeleton-alt icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="password" />
                </div>
              </div>
              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                  <input
                    className="prompt"
                    type="password"
                    name="re_password"
                    id="re_password"
                    maxLength="20"
                    placeholder="Retype password"
                    ref={register({
                      minLength: {
                        value: 6,
                        message: 'Use 6 characters or more for your password.'
                      },
                      validate: value =>
                        value === password.current || "The passwords do not match"
                    })}
                    onKeyUp={(e) => handleKeyPress(e.target.value)}
                  />
                  <i className="uil uil-key-skeleton-alt icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="re_password" />
                </div>
              </div>

              <div className="ui focus mt-15 captcha_container">

                  {/* <ReCAPTCHA
                    sitekey="Your client site key"
                    onChange={onChange}
                /> */}
                  </div>
              <button className="login-btn" type="submit">
                {translate.GENERAL.SIGN_UP}
              </button>
            </form>
          </div>
        ) : (
          <h4 className="success">{translate.REGISTER.SUCCESS}</h4>
        )}

        <p className="sgntrm145">
          {translate.REGISTER.TERMS_1}{' '}
          <Link to="/terms-conditions">{translate.REGISTER.TERMS_2}</Link>
        </p>

        <p className="mb-0 mt-30 hvsng145">
          {translate.GENERAL.HAVE_ACCOUNT}{' '}
          <Link to="/login">{translate.GENERAL.SIGN_IN}</Link>
        </p>
      </div>
    </React.Fragment>
  )
}
