import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './UserList.scss'

export default () => {
  const [userList, setUserList] = useState([])

  useEffect(() => {
    async function loadUsers() {
      const endpoint = '/api/v1/users'
      const response = await axios.get(endpoint)
      setUserList(response.data)
    }
    loadUsers()
  }, [])

  return (
    <ul class="userList">
      {/* {JSON.stringify(userList[0])} */}
      {userList &&
        userList.map((item) => {
          return (
            <li class="userList__item shadow-white">
              <div className="row">
                <div className="col-sm-3">
                  <label>Full Name</label>
                  <span className="text-capitalize">{item.name}</span>
                </div>
                <div className="col-sm-3">
                  <label>Username</label>
                  <span>{item.nickname}</span>
                </div>
                <div className="col-sm-3">
                  <label>Email</label>
                  <span>{item.email}</span>
                </div>
                <div className="col-sm-3">
                  <label>Approved</label>
                  {item.approved ? (
                    <span className="badge badge-success">Yes</span>
                  ) : (
                    <span className="badge badge-warning">No</span>
                  )}
                </div>
              </div>
            </li>
          )
        })}
    </ul>
  )
}
