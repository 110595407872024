import React, { useEffect, useState } from 'react'
import axios from 'axios'
import courseImg from '../../images/courses/img-2.jpg'

import './CourseList.scss'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [courses, setCourses] = useState(null)

  useEffect(() => {
    const endpoint = '/api/v1/course'
    axios.get(endpoint).then((response) => {
      setCourses(response.data)
    })
  }, [])

  return (
    <div className="courseList">
      <div className="row">
        <div className="col-md-12">
          <h2>Course List</h2>
          {courses &&
            courses.map((item, index) => {
              return (
                <div key={index} className="fcrse_1 mt-30 shadow-white">
                  <div>
                    <a href={"/dashboard/course/detail?courseid="+item._id} className="hf_img">
                      <img src={item.cover_image} alt="" />
                    </a>
                    <div className="hs_content">
                      <h1 className="title">
                        {item.title}
                        {item.mandatory ? (
                          <span className="ml-30 badge badge-danger">
                            Mandatory
                          </span>
                        ) : (
                          <span className="ml-30 badge badge-success">
                            Optional
                          </span>
                        )}
                      </h1>
                      <p className="category text-capitalize">
                        {item.category && item.category.name}
                      </p>
                      <h3 className="subtitle">{item.subtitle}</h3>
                      <ul className="more-info mt-30">
                        <li>
                          <i className="fa fa-user" /> 5
                        </li>
                        <li>
                          <i className="fa fa-history" /> 72:00:00
                        </li>
                        <li>
                          <i className="fa fa-language" /> English
                        </li>
                      </ul>
                      <div className="actions mt-40">
                        <a href={"/dashboard/course/detail?courseid="+item._id} className="btn-custom btn-success">
                         Open course 
                        </a>
                        <div className="pull-right">
                          <ul className="more-info mt-30">
                            <li></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
