import React, { useState, useEffect } from 'react'
import navItems from './Items'
import { NavLink } from 'react-router-dom'
import './Nav.scss'
import { useSelector } from 'react-redux'


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ userData }) => {
  const [userType, setUserType] = useState(null)
  const translate = useSelector((store) => store.translate)
  const my_courses = useSelector((store) => store.user.my_courses)


  useEffect(() => {
    if (userData.roles && userData.roles[0].name === 'student') {
      setUserType('student')
    } else {
      setUserType('company')
    }
  }, [])

  return (
    <nav className="vertical_nav masamo-nav" id="menu_lat">
      <div className="left_section menu_left" id="js-menu">
        <div className="left_section">
          <ul>
            {userType &&
              navItems[userType].map(({ name, className, linkRef }) => {
                let sh = true;
                
                if(name === "MYCOURSES" && my_courses.length === 0){

                  return 


                }else{
                return (
                <li className="menu--item" key={name}>
                    <NavLink to={linkRef} className="menu--link">
                      <i className={className}></i>
                      <span className="menu--label">
                        {translate.MAIN_MENU[name]}
                      </span>
                    </NavLink>
                  </li>
                
                  )
                }

              })}
          </ul>
        </div>
        <div className="left_section pt-2">
          <ul>
            <li className="menu--item">
              <a href="setting.html" className="menu--link" title="Setting">
                <i className="fa fa-cog menu--icon"></i>
                <span className="menu--label">
                  {translate.MAIN_MENU.SETTINGS}
                </span>
              </a>
            </li>
            <li className="menu--item">
              <a
                href="feedback.html"
                className="menu--link"
                title="Send Feedback"
              >
                <i className="fa fa-comment menu--icon"></i>
                <span className="menu--label">
                  {translate.MAIN_MENU.SEND_FEEDBACK}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
