import React, { useEffect, useState, useRef } from 'react'
import { useStore, useSelector } from 'react-redux'
import DashboardLayout from '../components/layout/DashboardLayout'
import { StudentDashboard } from './Student/DashboardNew'
import { CompanyDashboard } from './Company/Dashboard'

import socketIOClient from "socket.io-client";

export const Dashboard = () => {
  // const store = useStore()
  // const [userData, setUserData] = useState({})

  const NEW_CHAT_MESSAGE_EVENT = "newChatMessage"; // Name of the event
const SOCKET_SERVER_URL = process.env.REACT_APP_API_ENDPOINT;  

  const [showDashboard, setShowDashboard] = useState(false)
  const [role, setRole] = useState(false)
  const userData = useSelector((store) => store.user)
  const socketRef = useRef();
  const roomId = "094";

  useEffect(() => {

    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { roomId },
    });

    if (userData && userData.profile && userData.roles && userData.roles[0]) {
      //console.log('dashboard effect second', userData)
      setShowDashboard(true)
      setRole(userData.roles[0].name)
    
    }
  }, [userData])

  return (
    <DashboardLayout>
      {showDashboard && role === 'company' && (
        <CompanyDashboard profile={userData.profile} userData={userData} />
      )}

      {showDashboard && role === 'student' && (
        <StudentDashboard profile={userData.profile} userData={userData} />
      )}
    </DashboardLayout>
  )
}
