import React, { useEffect, useState } from 'react'
import axios from 'axios'
import courseImg from '../../images/courses/img-2.jpg'
import {ProgressBar} from 'react-bootstrap'
import './CourseListStudent.scss'
import { Link } from 'react-router-dom'
import { store, setCourseData,setActiveLesson } from '../../reducers/index'
import { useStore, useSelector } from 'react-redux'
const ld = require('lodash');


// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [courses, setCourses] = useState(null)
const my_courses_data = useSelector((store) => store.user.my_courses)   


console.log("My ",my_courses_data)

  useEffect(() => {
    const endpoint = '/api/v1/course'
    axios.get(endpoint).then((response) => {
      setCourses(response.data)
    })
  }, [])

  return (
    <div className="courseList">

<div className="page-section">
            <div className="media v-middle">
              <div className="media-body">
                <h1 className="text-display-1 margin-none">My courses</h1>
              </div>
              <div className="media-right">
                <a className="btn btn-white paper-shadow relative" data-z="0.5" data-hover-z="1" data-animated="" href="app-directory-list.html">All Courses</a>
              </div>
            </div>
          </div>


      <div className="row">
      {my_courses_data &&
            my_courses_data.map((item, index) => {
           //   console.log("kc ",courses)
         //     console.log("idi ", item)
           let course_data_item = ld.filter(courses, { _id: item.course })[0];  
              
      //     console.log("csd ",course_data_item)
           let progress_bar = 0;

           if(course_data_item){
         progress_bar = ((item.lessons.length / course_data_item.nro_lessons) * 100) 
           }

           console.log('pbr ', progress_bar)

      return(
      <div key={index} className="item col-xs-12 col-sm-6 col-lg-3" >
        <div className="panel panel-default paper-shadow">
        <div className="panel-heading">
                  <div className="media media-clearfix-xs-min v-middle">
                    <div className="media-body text-caption text-light2">
                      Lessons {item.lessons.length} of   {course_data_item && course_data_item.nro_lessons}
                    
                    </div>
                    <div className="media-right">
                      <div className=" width-100 margin-none">
                      <ProgressBar now={progress_bar} />
                    
                     </div>




      </div>
      </div>
      </div>
      <div className="cover overlay cover-image-full hover" >
                  <span className="img icon-block height-100 bg-default"></span>
                 
                 

                  <Link to={'/dashboard/course/?courseid='+item._id} className="padding-none overlay overlay-full icon-block bg-primary height-100 ">
                  <span className="v-center">
                <i className="fa fa-github"></i> 
            </span>
                    </Link>
                 
                 
                    <Link to={'/dashboard/course/?courseid='+item._id} className="overlay-full icon-block bg-primary height-100 ">

                     <span className="v-center">
                <span className="btn btn-circle btn-white btn-lg"><i className="fa fa-graduation-cap"></i></span>
                    </span>
                
                  </Link>
                </div>

                <div className="panel-body">
                  <h6 className="margin-v-0-2">
                    
                  {course_data_item && course_data_item.title}
                    
                    </h6>

                </div>
                <hr className="margin-none"/>

                <div className="panel-body">
                <Link to={course_data_item && '/dashboard/course/?courseid='+course_data_item._id} className="btn btn-white btn-flat paper-shadow relative">

                 Go to course
                  </Link>
                </div>



      </div>


      </div>
        )})}
      </div>
      
      {/* <div className="row">
        <div className="col-md-12">
          <h2>Course List</h2>
          {courses &&
            courses.map((item, index) => {
              return (
                <div key={index} className="fcrse_1 mt-30 shadow-white">
                  <div>
                    <a href="course_detail_view.html" className="hf_img">
                      <img src={courseImg} alt="" />
                    </a>
                    <div className="hs_content">
                      <h1 className="title">
                        {item.title}
                        {item.mandatory ? (
                          <span className="ml-30 badge badge-danger">
                            Mandatory
                          </span>
                        ) : (
                          <span className="ml-30 badge badge-success">
                            Optional
                          </span>
                        )}
                      </h1>
                      <p className="category text-capitalize">
                        {item.category && item.category.name}
                      </p>
                      <h3 className="subtitle">{item.subtitle}</h3>
                      <ul className="more-info mt-30">
                        <li>
                          <i className="fa fa-user" /> 5
                        </li>
                        <li>
                          <i className="fa fa-history" /> 72:00:00
                        </li>
                        <li>
                          <i className="fa fa-language" /> English
                        </li>
                      </ul>
                      <div className="actions mt-40">
                        <a href="#0" className="btn-custom btn-custom-primary">
                          Edit Course
                        </a>
                        <div className="pull-right">
                          <ul className="more-info mt-30">
                            <li></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div> */}
    </div>
  )
}
