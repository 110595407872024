import React, { useState, useEffect } from 'react'
import { useForm, ErrorMessage } from 'react-hook-form'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror'
import { options, menu } from '@aeaton/react-prosemirror-config-default'
import Dropdown from '../../components/common/Dropdown/Dropdown'
import axios from 'axios'

import './CourseForm.scss'

const languages = [
  {
    id: 'EN',
    name: 'English'
  },
  {
    id: 'ES',
    name: 'Español'
  },
  {
    id: 'PT',
    name: 'Portugues'
  },
  {
    id: 'DE',
    name: 'German'
  },
  {
    id: 'FR',
    name: 'French'
  },
  {
    id: 'IT',
    name: 'Italiano'
  }
]

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ nextStep }) => {
  const [html, setHtml] = useState('')
  const { register, errors, handleSubmit, setValue } = useForm()
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [expirationByDays, setExpirationByDays] = useState(true)



  useEffect(() => {
    loadCategory()
  }, [])

  const onChange = (val) => {
    setHtml(val)
    setValue('description', val, {
      shouldValidate: true,
      shouldDirty: true
    })
  }

  const toggleExpiration = (days) => {
    setExpirationByDays(days)
  }

  const onCategoryChange = (e) => {
    console.log('category change', e.target.value)
    loadCategory(e.target.value)
  }

  const loadCategory = (catId) => {
    const endpoint = `/api/v1/categories/${catId || '0'}`
    axios.get(endpoint).then((response) => {
      if (catId) {
        setSubCategories(response.data)
      } else {
        setCategories(response.data)
      }
      //console.log(response.data)
    })
  }

  const onSubmit = async (data) => {
    console.log('course form data', data)
    const endpoint = `/api/v1/course/`
    await axios.post(endpoint, data).then((response) => {
    
      console.log("http")
      nextStep({course:response.data.course_s})
      window.localStorage.setItem('idcs', response.data.course_s._id)
    
    
    })
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className="tab-from-content">
        <h2 className="title">
          <i className="fa fa-info-circle"></i>General Information
        </h2>
        <div className="course__form shadow-white">
          <div className="general_info10">
            <div className="row">
              {/* TITLE */}
              <div className="col-lg-6 col-md-6">
                <div className="ui search focus mt-30 lbel25">
                  <label>Course Title*</label>
                  <div className="ui input swdh19">
                    <input
                      className="prompt"
                      type="text"
                      placeholder="Insert your course title."
                      name="title"
                      maxLength="60"
                      id="title"
                      ref={register({
                        required: 'This field is required'
                      })}
                    />
                    <div className="badge_num">60</div>
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" />
                </div>
              </div>

              {/* SUBTITLE */}
              <div className="col-lg-6 col-md-6">
                <div className="ui search focus mt-30 lbel25">
                  <label>Course Subtitle*</label>
                  <div className="ui input swdh19">
                    <input
                      className="prompt"
                      type="text"
                      placeholder="Insert your course Subtitle."
                      name="subtitle"
                      data-purpose="edit-course-title"
                      maxLength="60"
                      id="subtitle"
                      ref={register({
                        required: 'This field is required'
                      })}
                    />
                    <div className="badge_num2">120</div>
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="subtitle" />
                </div>
              </div>

              {/* DESCRIPTION */}
              <div className="col-lg-12 col-md-12">
                <div className="course_des_textarea mt-30 lbel25">
                  <label>Course Description*</label>
                  <div className="desc-editor">
                   
                   <input
                   name="descriptionHTml"
                   value={html}
                   type="text"
                   ref={register()}/> 
                   
                   
                    <HtmlEditor
                      options={options}
                      value={html}
                      name="description"
                      onChange={onChange}
                      render={({ editor, view }) => (
                        <div>
                          <MenuBar menu={menu} view={view} />
                          {editor}
                        </div>
                      )}
                    />
                  </div>
                  {/* <DefaultEditor value={html} onChange={onChange} /> */}
                  <input
                    type="hidden"
                    id="description"
                    name="description"
                    value={html}
                    ref={register({
                      required: 'This field is required'
                    })}
                  />
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="description" />
                </div>
              </div>

              {/* CATEGORY */}
              <div className="col-lg-6 col-md-6">
                <div className="mt-30 lbel25">
                  <label>Choose Course Category*</label>
                </div>
                <Dropdown
                  name="category_id"
                  onChange={onCategoryChange}
                  register={register({
                    required: 'This field is required'
                  })}
                  options={categories}
                />
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="category_id" />
                </div>
              </div>

              {/* SUBCATEGORY */}
              <div className="col-lg-6 col-md-6" id="subcategory-container">
                <div className="mt-30 lbel25">
                  <label>Choose Course Subcategory*</label>
                </div>
                <Dropdown
                  name="subcategory_id"
                  register={register({
                    required: 'This field is required'
                  })}
                  options={subCategories}
                />
                <div className="error-message">
                  <ErrorMessage
                    as="span"
                    errors={errors}
                    name="subcategory_id"
                  />
                </div>
              </div>

              {/* LANGUAGE */}
              <div className="col-lg-6 col-md-16">
                <div className="mt-30 lbel25">
                  <label>Choose Language*</label>
                </div>
              
<select
      name="language"
      id="language"
      className="app-dropdown prompt"
      ref={register()}
    >
      <option className="default" value="">
       Select language
      </option>

      {languages &&
        languages.map(({ _id, name }) => {
          return (
            <option key={_id} value={_id}>
              {name}
            </option>
          )
        })}
    </select>


                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="subcategory" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="ui search focus mt-30 lbel25">
                  <label>Course Expiration Days*</label>

                  <div className="w-40">
                    <div className="flex-grow-1">
                      <div className="ui input swdh19">
                        <div className="radio-days mt-10">
                          <input
                            onChange={(e) => toggleExpiration(true)}
                            checked={expirationByDays}
                            id="days"
                            name="days"
                            type="radio"
                          />{' '}
                          By Days
                          <label htmlFor="days"></label>
                        </div>
                        <input
                          className="prompt"
                          type="number"
                          placeholder="10 Days"
                          name="expiration_days"
                          id="expiration_days"
                          ref={register(
                            expirationByDays && {
                              required: 'This field is required'
                            }
                          )}
                        />
                        <div className="badge_num">Days</div>
                      </div>
                      <div className="error-message">
                        <ErrorMessage
                          as="span"
                          errors={errors}
                          name="expiration_days"
                        />
                      </div>
                    </div>

                    <div className="flex-grow-1">
                      <div className="w-40">
                        <div className="ui input swdh19">
                          <div className="radio-date mt-10">
                            <input
                              onChange={(e) => toggleExpiration(false)}
                              checked={!expirationByDays}
                              id="date"
                              name="date"
                              type="radio"
                            />{' '}
                            By Date
                            <label htmlFor="date"></label>
                          </div>

                          <input
                            className="prompt"
                            type="number"
                            placeholder="DD/MM/YYYY"
                            name="expiration_date"
                            id="expiration_date"
                            ref={register(
                              !expirationByDays && {
                                required: 'This field is required'
                              }
                            )}
                          />
                        </div>
                      </div>
                      <div className="error-message">
                        <ErrorMessage
                          as="span"
                          errors={errors}
                          name="expiration_date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="ui search focus mt-30 lbel25">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="mandatory"
                      name="mandatory"
                      ref={register}
                    />
                    <label className="custom-control-label" htmlFor="mandatory">
                      Course Mandatory
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-30">
          <div className="col-lg-12 col-md-12 text-right">
            <div className="shadow-white px-30">
              <button className="save_btn" type="submit">
                Next Step
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
