const listItems = {
  student: [
    {
      name: 'HOME',
      className: 'fa fa-cube menu--icon',
      linkRef: '/dashboard'
    },
    {
      name: 'MYCOURSES',
      className: 'fa fa-book menu--icon',
      linkRef: '/dashboard/mycourses'
    },
    {
      name: 'COURSES',
      className: 'fa fa-book menu--icon',
      linkRef: '/dashboard/courses'
    },
    {
      name: 'NOTIFICATIONS',
      className: 'fa fa-bell menu--icon',
      linkRef: '/dashboard/analytics'
    }
  ],
  company: [
    {
      name: 'HOME',
      className: 'fa fa-cube menu--icon',
      linkRef: '/dashboard'
    },
    {
      name: 'ANALYTICS',
      className: 'fa fa-calculator menu--icon',
      linkRef: '/dashboard/analytics'
    },
    {
      name: 'COURSES',
      className: 'fa fa-book menu--icon',
      linkRef: '/dashboard/course/list'
    },
    {
      name: 'CATEGORIES',
      className: 'fa fa-folder-open menu--icon',
      linkRef: '/dashboard/categories'
    },
    {
      name: 'CREATE_COURSE',
      className: 'fa fa-plus-square menu--icon',
      linkRef: '/dashboard/course/new'
    },
    {
      name: 'EVALUATIONS',
      className: 'fa fa-align-center menu--icon',
      linkRef: '/dashboard/evaluations'
    },
    {
      name: 'ADD_EVALUATIONS',
      className: 'fa fa-plus-square menu--icon',
      linkRef: '/dashboard/add_evaluations'
    },
    {
      name: 'USERS',
      className: 'fa fa-user menu--icon',
      linkRef: '/dashboard/users'
    },
    {
      name: 'NOTIFICATIONS',
      className: 'fa fa-bell menu--icon',
      linkRef: '/dashboard/analytics'
    }
  ]
}

export default listItems
