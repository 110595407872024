import React from 'react'

import './Loader.scss'

export default () => {
  return (
    <div className="loader">
      <div className="loader__splash"></div>
    </div>
  )
}
