import React from 'react'
import OwlCarousel from 'react-owl-carousel2'
import news1 from '../../images/courses/Rectangle-7.png'
import news2 from '../../images/courses/news-2.jpg'
import { useSelector } from 'react-redux'

const carouselOptions = {
  items: 10,
  loop: false,
  margin: 30,
  nav: true,
  dots: false,
  navText: [
    "<i class='uil uil-angle-left'></i>",
    "<i class='uil uil-angle-right'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1000: {
      items: 1
    },
    1200: {
      items: 1
    },
    1400: {
      items: 1
    }
  }
}

const news = [
  {
    title: 'COVID-19 Updates & Resources',
    description:
      'See the latest updates to coronavirus-related content, including changes to monetization, and access new Creator support resources',
    image: news1
  },
  {
    title: 'Watch: Edututs+ interview Mr. Joginder',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ac eleifend ante. Duis ac pulvinar felis. Sed a nibh ligula. Mauris eget tortor id mauris tristique accumsan.',
    image: news2
  }
]

export default () => {
  const translate = useSelector((store) => store.translate)

  return (
    <div className="la5lo1 shadow shadow-seamless-white">
      <OwlCarousel
        options={carouselOptions}
        className="owl-carousel edututs_news owl-theme"
      >
        {news.map(({ title, description, image }) => {
          return (
            <div className="item" key={title}>
              <div className="fcrse_1">
                <a href="#0" className="fcrse_img">
                  <img src={image} alt="" />
                </a>
                <div className="fcrse_content">
                  <a href="#0" className="crsedt145 mt-15">
                    {title}
                  </a>
                  <p className="news_des45">{description}</p>
                  <div className="auth1lnkprce">
                    <a href="#0" className="cr1fot50 grey card-actions">
                      {translate.GENERAL.LEARN_MORE}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </OwlCarousel>
    </div>
  )
}
