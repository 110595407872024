import React from 'react'

import graduationCap from '../../images/cs1.png'
import onlineCourse from '../../images/cs2.png'
import knowledge from '../../images/cs3.png'
import { useSelector } from 'react-redux'

const indicators = [
  {
    name: 'TOTAL_ENROLL',
    value: 1500,
    image: knowledge
  },
  {
    name: 'TOTAL_COURSES',
    value: 130,
    image: graduationCap
  },
  {
    name: 'TOTAL_STUDENTS',
    value: 130,
    image: onlineCourse
  }
]

export default () => {
  const translate = useSelector((store) => store.translate)

  return (
    <React.Fragment>
      {indicators.map(({ name, value, image }) => {
        return (
          <div className="col-xl-3 col-lg-6 col-md-6" key={name}>
            <div className="card_dash">
              <div className="card_dash_left">
                <h5>{translate.INDICATORS[name]}</h5>
                <h2>{value}</h2>
                <span className="crdbg">New 125</span>
              </div>
              <div className="card_dash_right">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        )
      })}
    </React.Fragment>
  )
}
