import React from 'react'
import LoginForm from '../components/auth/LoginForm'
import AuthLayout from '../components/layout/AuthLayout'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

export const login = () => {
  return (
    
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfUG58aAAAAALwe41YLcr85T4JuCo4e4PvXVs4O"
      >
        <AuthLayout>
        <LoginForm />

        <GoogleReCaptcha />
        </AuthLayout>
      </GoogleReCaptchaProvider>
    

    
  )
}
