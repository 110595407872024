import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default ({ name, register, options, onChange, value }) => {
  const translate = useSelector((state) => state.translate)

  useEffect(() => {
    //console.log('change value', value)
  }, [value])

  return (
    <select
      name={name}
      id={name}
      onChange={onChange}
      className="app-dropdown prompt"
      ref={register}
    >
      <option className="default" value="">
        {translate.GENERAL.SELECT_VALUE}
      </option>

      {options &&
        options.map(({ _id, title }) => {
          return (
            <option key={_id} value={_id}>
              {title}
            </option>
          )
        })}
    </select>
  )
}
