import React, { Component,useState, useEffect } from 'react'
import { Accordion, Icon,Embed } from 'semantic-ui-react'
import { Checkbox } from 'semantic-ui-react'
import {Dropdown} from 'semantic-ui-react'
import axios from 'axios'
import './lessonView.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import { store, setCourseData,setActiveLesson,setMicoursesUserData } from '../../reducers/index'

import { useStore, useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
const ld = require('lodash');


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
 
  console.log("proplss onview  ",props)
    const state = {
      lesson_active:useSelector((store) => store.openLesson) ,
      course_load:{},
      playing:true,
      course_enrrol:{}
    }
    
    state.lesson_active =  useSelector((store) => store.openLesson)   
    state.course_load = props.course
    
    console.log("MC LVW ",props.my_courses)

    console.log("lesson active un lviw  ",state.lesson_active)


    const [course_enrrolled, setEnrolled] = useState({})
  
  
    
    const enrrol = ld.filter(props.my_courses, { course: state.course_load._id } );
    
    state.course_enrrol = enrrol[0]; 

   
  
    const no_have_selected = (obj) => {
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) return false;
      }
    
      return true;
    }

    
    const set_next_lesson = async () => {
      
      console.clear()
      props.setAutoplay(false)
     // console.log("a cambiar estado:  ",state.lesson_active)
      const id_lesson_selected = state.lesson_active._id
      const endpoint = '/api/v1/lesson/set_next_status/'+id_lesson_selected+'/'+window.localStorage.getItem('userId')+'/'+state.course_load._id;
      var clonel = {...state.lesson_active};
           
      clonel.status = 'lesson_completed'
      await axios.get(endpoint).then((response) => { 
   //     console.log("next result l ",response)

       

        props.setAutoplay(true)
       
          props.reload(response.data.mis_cursos) 
         
     
         run_lessons()

      
        

      })

    }



    const run_lessons = ()=>{

      console.log("run lessonss _______________________________________")

      if(props.autoplay_state === true){


     //buscar no completados
     
        //comparo si ya estan todas la lessiones completadas
      //  console.log(props)
        const enrrol = ld.filter(props.my_courses, { course: props.course._id } );
        console.log("my courserun lessons ", enrrol[0])
      


       if(enrrol.length>0){

    //    console.clear()
      
//  console.clear()
if(enrrol[0].lessons.length > 0){
  //recorro todo elarbol de topics lessons y busco la primera no vista
  let playl = true;
  props.course.topics.map((topic,index)=>{
  //  console.log("buscando en topic ", index)

   return topic.lessons.map((lesson, index)=>{
     // console.log("verificando lesson ",lesson._id)
      //buscar si es vista
     const es_vista = ld.filter(enrrol[0].lessons, { _id: lesson._id } );
   //   console.log("es vista? ",es_vista)
 
      if(es_vista.length===0 && playl === true){
  //   console.log(" play ", lesson)

     state.lesson_active = lesson

        console.log("play lesson ", state.lesson_active)

     playl = false

      }else{
      //  console.log("nf in mycourses ")
      }


    })
//     


  })



  store.dispatch(setActiveLesson(state.lesson_active))

}else{
  console.log("start course")
 
state.lesson_active = state.course_load.topics[0].lessons[0];
store.dispatch(setActiveLesson(state.lesson_active))
}


       }else{

        console.log("start course")
        if(props.course.topics){
        state.lesson_active = props.course.topics[0].lessons[0];
        store.dispatch(setActiveLesson(state.lesson_active))
        }

       }

      }else{
        console.log("autoplay off")
      }

    }

   


    const pause_lesson = () =>{
     
    }

      const tpl = state.lesson_active;

      useEffect(() => {

        
      run_lessons()


      })
        return(
          

    <div>
   
    <div className="video_container row videoport">
    
    
    <ReactPlayer 
      url={tpl.video_url} 
      onPause={pause_lesson} 
      onEnded={set_next_lesson} 
      controls={true} 
      playing={state.playing}
      width='100%'
      height='400px'
    />

      
			
			</div>

      <div className="space"></div>

			<div className="row title_topic_load">
			  <h3>{tpl.title}</h3>
			</div>

      <div className="space"><hr/></div>

      <div className="row time_details">
			<span><Icon name='video'/> Video</span>  <span>   <Icon name='clock'/> {tpl.duration}</span>
			</div>
      <div className="space"><hr/></div>
      <div className="row-fluid topic_description">
		<div className="col-md-12 ">
    <p>	{tpl.lesson_description}</p></div>


<div className="col-md-5 "> 
 <br/>
resources
</div>

{/* <div>
    <Button content='Pause' icon='pause' labelPosition='left' />
    <Button content='Next' icon='right arrow' labelPosition='right' />
  </div> */}

{/* <span className="ft-tp-iv"><button className="btn btn-controls-course-success"><Icon name='pause'/> Pause</button></span> */}

<span className="ft-tp-id"><button onClick={set_next_lesson} className="btn btn-controls-course-default"><Icon name='play'/> Next Lecture</button></span>

      </div>
      <div className="space"><hr/></div>


     </div> 
    )

}     