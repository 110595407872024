import axios from 'axios'
import React, { useState } from 'react'
import AuthManager from '../../helpers/AuthManager'
import { useForm, ErrorMessage } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import TwitterLogin from "react-twitter-login";
import LinkedIn from "linkedin-login-for-react";
import InstagramLogin from 'react-instagram-login';
import GoogleLogin  from  'react-google-login' ;
import ReCAPTCHA from "react-google-recaptcha";
import { SocialIcon } from 'react-social-icons';


//import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular'

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const { register, errors, handleSubmit } = useForm()
  const [authError, setAuthError] = useState(null)
  const isLoading = useSelector((state) => state.loading)
  const translate = useSelector((state) => state.translate)

  let history = useHistory()
  //console.log('entry to login form')

  const onChange = (value) => {
    //console.log("Captcha value:", value);
  }

  const responseGoogle = (response) => {
    //console.log(response);
  }
 
  const callbackLinkedIn = (error, code, redirectUri) => {
    if (error) {
      // signin failed
    } else {
      // Obtain authorization token from linkedin api
      // see https://developer.linkedin.com/docs/oauth2 for more info
    }
  };

  const responseInstagram = (response) => {
    //console.log(response);
  }

  const authHandler = (err, data) => {
    //console.log(err, data);
  };

  const responseFacebook = (response) => { 
    //console.log(response);
  }

  const onSubmit = (data) => {
    axios
      .post('/auth/sign_in', data)
      .then((response) => {

        if(response.data.result){

        if (response.data.user.approved === '0') {
          setAuthError([`Your account hasn't been approved.`])
        } else {
          AuthManager.setToken(response.headers, response.data.user)
          history.push('/dashboard/courses/')
        }

      }else{
        setAuthError([response.data.err.message])
      }


      })
      .catch((error) => {
        if (error.response) {
          //console.log(error.response.data)
          setAuthError(error.response.data.errors)
        }
      })
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
    }
  }

  return (
    <React.Fragment> 
      <div className="sign_form">
        <h2>{translate.GENERAL.WELCOME}</h2>
        <p>{translate.LOGIN.LOGIN_ACCOUNT}</p>
        <ul className="tutor_social_links">
              <li>
                <a
                  href={`#`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fb"
                >
                  <i className="fa fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href={`#`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tw"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href={`#`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ln"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href={`#`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ln"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
        
        {/* <ul >
              <li>
              
              </li>
              <TwitterLogin
                authCallback={authHandler}
                consumerKey={"6BsNR3gXG22530yoh0CR3CJbq"}
                consumerSecret={"U1tDQaT9DRPx1AOMkDKrM7zOryj1rZXXEMmxTxEIGgUiJycWFO"}
                className= "socialbuttonM"
              />
              <LinkedIn
                clientId="8693y4vgf1enmi"
                callback={callbackLinkedIn}
                className={"socialbuttonM"}
                scope={["r_liteprofile","r_emailaddress"]}
                text="Login With LinkedIn"
              />
               <InstagramLogin
                clientId="5fd2f11482844c5eba963747a5f34556"
                buttonText="Login"
                onSuccess={responseInstagram}
                onFailure={responseInstagram}
              />
              <GoogleLogin
                clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </ul> */}

        <div className="error-message danger">
          {authError && authError.join(',')}
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="ui focus mt-15">
            <div className="ui left icon input swdh95">
              <input
                className="prompt"
                type="text"
                name="email"
                id="email"
                maxLength="40"
                placeholder="Email Address"
                ref={register({
                  required: 'This field is required.',
                  maxLength: 40,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Invalid Email Address'
                  }
                })}
              />
              <i className="uil uil-envelope icon icon2"></i>
            </div>
            <div className="error-message">
              <ErrorMessage as="span" errors={errors} name="email" />
            </div>
          </div> */}
          <div className="ui focus mt-15">
            <div className="ui left icon input swdh95">
              <input
                className="prompt"
                type="text"
                name="nickname"
                id="nickname"
                maxLength="20"
                placeholder={translate.GENERAL.USERNAME}
                ref={register({
                  required: translate.GENERAL.REQUIRED,
                  minLength: {
                    value: 3,
                    message: 'Use 3 characters or more for your username.'
                  }
                })}
              />
              <i className="uil uil-user-circle icon icon2"></i>
            </div>
            <div className="error-message">
              <ErrorMessage as="span" errors={errors} name="nickname" />
            </div>
          </div>
          <div className="ui focus mt-15">
            <div className="ui left icon input swdh95">
              <input
                className="prompt"
                type="password"
                name="password"
                id="password"
                maxLength="20"
                placeholder={translate.GENERAL.PASSWORD}
                ref={register({
                  required: translate.GENERAL.REQUIRED,
                  maxLength: 20,
                  minLength: {
                    value: 6,
                    message: 'Use 6 characters or more for your password.'
                  }
                })}
                onKeyUp={(e) => handleKeyPress(e.target.value)}
              />
              <i className="uil uil-key-skeleton-alt icon icon2"></i>
            </div>
            <div className="error-message">
              <ErrorMessage as="span" errors={errors} name="password" />
            </div>
          </div>
          <div className="ui focus mt-15">
            
  
          </div>
          <button
            className="login-btn"
            type="submit"
            disabled={isLoading ? 'disabled' : ''}
          >
            {translate.GENERAL.SIGN_IN}
          </button>
        </form>
        <p className="sgntrm145">
          Or{' '}
          <Link to="/forgot-password">{translate.GENERAL.FORGOT_PASSWORD}</Link>
        </p>
        <p className="mb-0 mt-30 hvsng145">
          {translate.GENERAL.DONT_HAVE_ACCOUNT}{' '}
          <Link to="/register">{translate.GENERAL.SIGN_UP}</Link>
        </p>
      </div>
    </React.Fragment>
  )
}
