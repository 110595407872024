import * as pages from '../pages/Index'

const routes = [
  // {
  //   name: 'landing',
  //   path: '/',
  //   component: pages.login,
  //   meta: {
  //     requireAuth: false
  //   }
  // },
  {
    name: 'login',
    path: '/login',
    component: pages.login,
    meta: {
      requireAuth: false
    }
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: pages.forgotPassword,
    meta: {
      requireAuth: false
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: pages.resetPassword,
    meta: {
      requireAuth: false
    }
  },
  {
    name: 'register',
    path: '/register',
    component: pages.register,
    meta: {
      requireAuth: false
    }
  },
  {
    name: 'register',
    path: '/register-company',
    component: pages.registerCompany,
    meta: {
      requireAuth: false
    }
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: pages.dashboard,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'home',
    path: '/dashboard-company',
    component: pages.companyDashboard,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'profile',
    path: '/profile',
    component: pages.profile,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'create-course',
    path: '/dashboard/course/new',
    component: pages.createCourse,
    meta: {
      requireAuth: true
    }
  }, 
  {
    name: 'course-view',
    path: '/dashboard/course',
    component: pages.courseView,
    meta: {
      requireAuth: true
    }
  }, 
  {
    name: 'categories',
    path: '/dashboard/categories',
    component: pages.categoriesAdmin,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'course-viewl',
    path: '/dashboard/course/detail',
    component: pages.courseViewL,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'course-list',
    path: '/dashboard/courses',
    component: pages.courseLists,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'course-list-student',
    path: '/dashboard/mycourses',
    component: pages.courseListStudent,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'users',
    path: '/dashboard/users',
    component: pages.users,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'account',
    path: '/activate_account/',
    component: pages.activateAccount,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'evaluations',
    path: '/dashboard/evaluations',
    component: pages.evaluationsPage,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'add_evaluations',
    path: '/dashboard/add_evaluations',
    component: pages.addEvaluationspage,
    meta: {
      requireAuth: true
    }
  },
  {
    name: 'make_evaluation',
    path: '/dashboard/course/evaluate',
    component: pages.formEvaluationPage,
    meta: {
      requireAuth: true
    }
  }

]

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  public: routes.filter((route) => route.meta.requireAuth === false),
  private: routes.filter((route) => route.meta.requireAuth === true),
  all: routes
}
