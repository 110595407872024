import React, { useState, useEffect } from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import ProfileForm from './Profile/ProfileForm'
import axios from 'axios'

const Profile = () => {
  const userId = window.localStorage.getItem('userId')
  const [profileData, setProfileData] = useState(null)

  //empty array to tell use effect execute just once after render
  //eslint-disable-next-line import/no-extraneous-dependencies
  useEffect(() => {
    loadData()
  }, [])

  function loadData() {
    axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
    const endpoint = `/api/v1/users/${userId}/profile`
    axios
      .get(endpoint)
      .then((response) => {
        //console.log(response.data)
        setProfileData(response.data)
      })
      .catch((error) => {
        //console.log(error.response)
        setProfileData(error)
      })
  }

  return (
    <DashboardLayout>
      <div className="row">
        <div className="col-lg-12">
          <h2 className="st_title">
            <i className="uil uil-cog"></i> Setting
          </h2>
          {profileData && <ProfileForm data={profileData} />}
        </div>
      </div>
    </DashboardLayout>
  )
}

export const profile = Profile
