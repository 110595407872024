import React from 'react'
import ResetPasswordForm from '../components/auth/ResetPasswordForm'
import AuthLayout from '../components/layout/AuthLayout'

export const resetPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  )
}
