import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import AddEvaluations from './Company/AddEvaluations'

import { useStore, useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";




export const AddEvaluationsPage = (props) => {
 
  
  return (
    <DashboardLayout>
     <AddEvaluations/>
    </DashboardLayout>
  )
}
