import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import View from './Course/courseView'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const CourseView = () => {
  
  let query = useQuery();
  //console.clear()
  //console.log("cidm ",query.get("courseid"))
  return (
    
    <DashboardLayout>
      <View courseid={query.get("courseid")}/>
    </DashboardLayout>
  )
}
