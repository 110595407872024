import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ title, video_url ,key,defaultImg, description, uploadMethod, register }) => {
  const translate = useSelector((state) => state.translate)
console.log("key", title)
const [urlf,setUrl] = useState(false)

useEffect(() => {


  
if(title === 'VIDEO_TITLE'){
  setUrl(true)
  }else{
    setUrl(false) 
  }

  
if(title === 'IMG_TITLE'){

  window.jQuery(function ($) {

    const readURL = (input) => {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
  
        reader.onload = function (e) {
          $('#im_'+title).attr("src", e.target.result);
        };
  
        reader.readAsDataURL(input.files[0]);
      }
    }
  

   
    $("#f_"+title).change(function(e){

      readURL(this);
   
    })


  })

}



}, [])

  return (
    <div className="view_all_dt">
      <div className="view_img_left">
        <div className="view__img">
          <img src={defaultImg} alt="" id={"im_"+title}/>
        </div>
      </div>
      <div className="view_img_right">
        <h4>{translate.MEDIA[title]}</h4>
        <p>{translate.MEDIA[description]}</p>
        <div className="upload__input">
          <div className="input-group">

            
            <div className="custom-file">
              
             
            
          

              <input
                type="file"
                className="custom-file-input"
                id={'f_'+title}
                ref={register()}
                name={title}
              />
              <label className="custom-file-label">Choose file</label>


            </div>

       
          </div>

          <br/>
          {urlf ? (
           
          <div className="form-group">

            <div className="custom-file">

                
           <input
              type="text"
              className="form-control"
              name="video_url"
              placeholder="Insert video URL"
              ref={register()}/>
           
           <p>Can add Youtube, Dailymotion, Vimeo url</p>


            </div>
          </div>
           ):(<></>)}
        </div>
      </div>
    </div>
  )
}
