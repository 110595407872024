import React, { useEffect, useState, Component } from 'react'
import axios from 'axios'
import courseImg from '../../images/courses/img-2.jpg'
import DetailsTabPanel from './courseDetailLayout'
import TopicsPanel2 from './topicsPanel.L'
import {Button, Divider, Embed, List, Card, Image, Accordion, Icon ,Tab, Dropdown, AccordionTitle } from 'semantic-ui-react'
import './CourseViewL.scss'
import FeedLesson from './LessonFeed'
import CommentsLessons from './LessonComments'
import {Tabs} from 'react-bootstrap';
import { useStore, useSelector } from 'react-redux'
import { store, setCourseData,setUserData,setMicoursesUserData} from '../../reducers/index'
import swal from 'sweetalert'
import { Link, useHistory } from 'react-router-dom'
import qs from 'qs'

const ld = require('lodash');



// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {

  const [coursed, setCourse] = useState({title:''})
  const [my_courses_data_load, setMyCourse] = useState([])
 const [is_suscribed, setSucribed] = useState(false)
 const[html, htmlCtn] = useState('')
 const [newCourses, addNewC]=useState([])

 const [loading, setLoading] = useState(true)
	

	
 const my_courses_data = useSelector((store) => store.user.my_courses)   
 console.log("flt ",my_courses_data)

  let history = useHistory()

const gotoCourse = (courseid) => {
	history.push("/dashboard/course/?courseid="+courseid)
	
}

 const assignCourse = async (coursedid, userid) => {
		 
	 console.log("idcu ",props.userData.id)


	 const sessionData = JSON.parse(window.localStorage.getItem('auth-data'))

	console.log(sessionData)

    if (sessionData) {
      axios.defaults.headers.common = {}
	  
	}
 
	 const endpoint = '/api/v1/course/assigncourse/'


	 let bodyFormData = new FormData();
 console.log('props ',props)
	 bodyFormData.append('course', props.courseid)
	 bodyFormData.append('user', props.userData.id)
	
	 var new_user_data= {...props.userData}

	 console.log("user dtak k ",new_user_data.my_courses)
	
	 await axios
      .post(endpoint, qs.stringify({id_course:props.courseid, id_user:props.userData.id}))
      .then((response) => {
		

		
		addNewC(response.data.my_courses)

		setTimeout(async ()=>{

		new_user_data.my_courses = response.data.my_courses;
		
		console.log("matricula ", new_user_data)
		
		store.dispatch(setMicoursesUserData(new_user_data.my_courses))
		
		setSucribed(true)

	swal("Good job!", "You enroll this course now!", "success", {
			buttons:{
				cancel: {
				  text: "Cancel",
				  value: null,
				  visible: false,
				  className: "",
				  closeModal: true,
				},
				confirm: {
				  text: "OK",
				  value: true,
				  visible: true,
				  className: "",
				  closeModal: true
				}
			  },
		  }).then((gotoCourse) => {
		 
		//  history.push("/dashboard/course/?courseid="+props.courseid)
		  });

		},500)
      })
      .catch((error) => {
        if (error.response) {
          
	return true;
        }
      })
 
 } 

  useEffect(() => {
   
    const fetch_course = async () => {
    
	
		try {

    console.log("llamando datos ...")
	
	setLoading(true)
    const endpoint = '/api/v1/course/'+props.courseid
    await axios.get(endpoint).then((response) => {
    //  setCourses(response.data)
   
		setLoading(false)
    const courseData = response.data

	    // convert editorState to plain HTML
		

    console.log("Datos curso: ",courseData)

	if(ld.filter(my_courses_data, { course: courseData._id }).length > 0){
		console.log("ya estoy matriculado ")
	
		setSucribed(true)

	  }
		

    setCourse(courseData);
	store.dispatch(setCourseData(courseData))
    console.log("curso estado ", coursed)
    	
	

    })
  } catch (e) {
    //console.log(e);
    setCourse({title: "", isFetching: false});

  }
}

fetch_course();

  }, [])


  


  return (
    
    <div className= 'course_container' >
      <div className= 'row'>
        <div className= "col-xs-12 col-md-12 col-lg-12">
          <div className="row sdf">
            <div className= "col-xs-4 col-md-4 col-lg-4">
              <div className = "preview_video">
                <a href="#" className="fcrse_img" >

                  <img src={coursed.cover_image}/>
                  <div className="course-overlay">
						<div className="badge_seller">Bestseller</div>
							<span className="play_btn1"><i className="uil uil-play"></i></span>
							<span className="_215b02">Preview this course</span>
						</div><div className="course-overlay">
					<div className="badge_seller">Bestseller</div>
						<span className="play_btn1"><i className="uil uil-play"></i></span>
						<span className="_215b02">Preview this course</span>
					</div>
                </a>
              </div>
            </div>
            <div className= "col-xs-8 col-md-8 col-lg-8">
                    <div className="df45">
                      <h3>{coursed.title}</h3>
					  <small>{coursed.subtitle}</small>
                      <span className="_215b04"></span>
                      </div> 
                      <div className="_215b05">
										<div className="crse_reviews mr-2">
											<i className="uil uil-star"></i>0
										</div>
										(0 ratings)
									</div> 
                  <div className="_215b05">										
										0 students enrolled
									</div>
                  <div className="_215b06">										
										<div className="_215b07">										
											<span><i className="uil uil-comment"></i></span>
											English
										</div>
										<div className="_215b08">										
											{/* <span><i className="uil uil-closed-captioning"></i></span>
											<span>English, Dutch
												<span className="caption_tooltip">
													12 more
													<span className="caption-content">
														<span>French</span>
														<span>Hindi</span>
														<span>German [Auto-generated]</span>
														<span>Indonesian [Auto-generated]</span>
														<span>Italian [Auto-generated]</span>
														<span>Japanese [Auto-generated]</span>
														<span>Korean</span>
														<span>Polish</span>
														<span>Portuguese [Auto-generated]</span>
														<span>Spanish [Auto-generated]</span>
														<span>Traditional Chinese</span>
														<span>Turkish [Auto-generated]</span>
													 </span>
												</span>
											</span> */}
										</div>
									</div>
                  <div className="_215b05">										
										Last updated 4/2021
									</div>
                  <ul className="_215b31">										
							{!loading ? (
						<>
							{is_suscribed ? (
										<li><button className="btn_adcart" onClick={() => gotoCourse(coursed._id)} >Go to course</button></li>
							
								):(

									<li><button className="btn_adcart" onClick={() => assignCourse(coursed._id, props.userData._id)} >Start course</button></li>
							

								)}	
							</>
							):(
								<Button loading className="btn_adcart">Loading</Button>
							
							)}
							
										{/* <li><button className="btn_buy">Buy Now</button></li> */}
									</ul> 
                  {/* <div className="_215fgt1">										
										30-Day Money-Back Guarantee
									</div> */}
                </div>
                
          
          
          </div>

          <div className="_215b15 _byt1458">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="user_dt5">
							<div className="user_dt_left">
								<div className="live_user_dt">
									<div className="user_img5">
										<img src={coursed.cover_image} alt=""/>									
									</div>
									<div className="user_cntnt">
										<a href="#" className="_df7852">Johnson Smith</a>
										<button className="subscribe-btn">Subscribe</button>
									</div>
								</div>
							</div>
							<div className="user_dt_right">
								<ul>
									<li>
										<a href="#" className="lkcm152"><i className="uil uil-eye"></i><span>0</span></a>
									</li>
									<li>
										<a href="#" className="lkcm152"><i className="uil uil-thumbs-up"></i><span>0</span></a>
									</li>
									<li>
										<a href="#" className="lkcm152"><i className="uil uil-thumbs-down"></i><span>0</span></a>
									</li>
									<li>
										<a href="#" className="lkcm152"><i className="uil uil-share-alt"></i><span>0</span></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
 
    </div> 
         
  </div>
  <Tabs defaultActiveKey="About" id="uncontrolled-tab-example">
  <Tab eventKey="About" title="About">
  
  <div className="_htg452 mt-35">
			<h3>Description</h3>
		<p>{coursed.description}</p>

	</div>
  </Tab>

  
  <Tab eventKey="Courses content" title="Courses content">
  	
   <TopicsPanel2 topics={coursed.topics}/> 	


  </Tab>
  
  <Tab eventKey="Reviews" title="Reviews" >
  <div className="tab-pane fade activite show" id="nav-reviews" role="tabpanel">
   <div className="student_reviews">
	<div className="row">
			
		  <div className="col-lg-12 col-md-12 col-xs-12">
			 <div className="review_rigth">
				 <div className="review_right_heading">
					<h3>Reviews</h3>
				 </div>
				 <div className="review_search">
					<input className="rv_srch" type="text" placeholder="Search reviews"></input>
					<button class="rvsrch_btn"><i class="uil uil-search"></i></button>
				 </div>
				 <div className="review_all120">
					<div className="review_all120">
						<div className="review_item">
							<div className="review_usr_dt">
							<a href="#"><img src="/img-3.jpg" alt=""/></a>
							<div className="rv1458">
								<h4 className="tutor_name1">John Doe</h4>
								<span className="time_145">2 hour ago</span>
							</div>
							</div>
							<div className="rating-box mt-20">
							   <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
							</div>
							<p className="rvds10">
								"Nam gravida elit a velit rutrum, eget dapibus ex elementum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia, nunc sit amet tincidunt venenatis."
							</p>
						</div>
						<div className="review_item">
							<div className="review_usr_dt">
							<a href="#"><img src="/img-4.jpg" alt=""/></a>
							<div className="rv1458">
								<h4 className="tutor_name1">Tina Thompson</h4>
								<span className="time_145">3 hour ago</span>
							</div>
							</div>
							<div className="rating-box mt-20">
							   <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
							</div>
							<p className="rvds10">
								"Nam gravida elit a velit rutrum, eget dapibus ex elementum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia, nunc sit amet tincidunt venenatis."
							</p>
						</div>
						<div className="review_item">
							<div className="review_usr_dt">
							<a href="#"><img src="/img-5.jpg" alt=""/></a>
							<div className="rv1458">
								<h4 className="tutor_name1">Jose Thompson</h4>
								<span className="time_145">3 hour ago</span>
							</div>
							</div>
							<div className="rating-box mt-20">
							   <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
					           <Icon name='star'/>
							</div>
							<p className="rvds10">
								"Nam gravida elit a velit rutrum, eget dapibus ex elementum. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia, nunc sit amet tincidunt venenatis."
							</p>
						</div>
						<div class="review_item">
							<a href="#" class="more_reviews">See More Reviews</a>
						</div>
					</div>
				 </div>
			 </div>
		  </div>
		</div>
	</div>
</div>

  </Tab>
</Tabs>



  </div>
         
       
       
    )

          

}


