import React from 'react'
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm'
import AuthLayout from '../components/layout/AuthLayout'

export const forgotPassword = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  )
}
