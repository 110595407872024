import React from 'react'
import { Link } from 'react-router-dom'
import CourseCarousel from './CourseCarousel'
import NewsCarousel from './NewsCarousel'
import Indicators from './Indicators'
import { useSelector } from 'react-redux'

export function CompanyDashboard() {
  const translate = useSelector((state) => state.translate)

  return (
    <section className="company-dashboard">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="st_title">
            <i className="uil uil-apps"></i> {translate.DASHBOARD.COMPANY.TITLE}
          </h2>
        </div>

        <Indicators />

        <div className="col-md-12">
          <div className="card_dash1">
            <div className="card_dash_left1">
              <h1>{translate.DASHBOARD.COMPANY.JUMP_COURSE}</h1>
            </div>
            <div className="card_dash_right1 mt-10">
              <Link to="/course/new" className="btn-custom btn-custom-primary">
                {translate.GENERAL.CREATE_COURSE}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6">
          <div className="section3125 mt-50">
            <h4 className="item_title">
              {translate.DASHBOARD.COMPANY.LATEST_COURSE_PERFORMANCE}
            </h4>
            <CourseCarousel />
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6">
          <div className="section3125 mt-50">
            <h4 className="item_title">{translate.DASHBOARD.COMPANY.NEWS}</h4>
            <div className="la5lo1">
              <NewsCarousel />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6">
          <div className="section3125 mt-50">
            <h4 className="item_title">
              {translate.DASHBOARD.COMPANY.PROFILE_ANALYTICS}
            </h4>
            <div className="la5lo1 shadow shadow-seamless-white">
              <div className="fcrse_1">
                <div className="fcrse_content">
                  <h6 className="crsedt8145">
                    {translate.DASHBOARD.COMPANY.CURRENT_SUBSCRIBERS}
                  </h6>
                  <h3 className="subcribe_title">856</h3>
                  <div className="allvperf">
                    <div className="crse-perf-left">
                      {translate.GENERAL.VIEW}
                    </div>
                    <div className="crse-perf-right">
                      17k
                      <span className="analyics_pr">
                        <i className="uil uil-arrow-to-bottom"></i>75%
                      </span>
                    </div>
                  </div>
                  <div className="allvperf">
                    <div className="crse-perf-left">
                      {translate.GENERAL.ENROLL}
                    </div>
                    <div className="crse-perf-right">
                      50
                      <span className="analyics_pr">
                        <i className="uil uil-top-arrow-from-top"></i>70%
                      </span>
                    </div>
                  </div>
                  <div className="auth1lnkprce">
                    <a href="#0" className="cr1fot50 grey card-actions">
                      {translate.DASHBOARD.COMPANY.GO_TO_ANALYTICS}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
