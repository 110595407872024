import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { useForm, ErrorMessage } from 'react-hook-form'
import { ReactFormBuilder } from 'react-form-builder2';
import { Dropdown } from 'semantic-ui-react'
import './Add_Evaluations.scss'
import 'react-form-builder2/dist/app.css';


const ld = require('lodash');



const columns = [
  {
    name: 'Title',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Slug',
    selector: 'slug',
    sortable: true,
    right: true,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {

  
 const { register, errors, handleSubmit ,setValue} = useForm()


 const {
  register: register2,
  errors: errors2,
  handleSubmit: handleSubmit2
} = useForm();


  const [success, setSuccess] = useState(null)
  const [list_add_evaluations, setEvaluationsList] = useState([])

  const [list_add_evaluations_parents, setParentsList] = useState([])

  const [combo_courses, setcomboCList] = useState([])
  const [combo_topics, setcomboTList] = useState([])
  const [answers_list, setAnswerList] = useState([])
  const [replies_list, setreppliesList] = useState([{}])

  const [evaluation_name, setEvName] = useState('')
  const [evaluation_type, setTypeEv] = useState('')
  const [evaluation_course, setCourse] = useState('')
  const [evaluation_topic, setTopic] = useState('')

  const [disabletp, setDisabled] = useState(true)

  const onSubmit = (data,e) => {
   

    data.replies_list = []
    
    setAnswerList([...answers_list, data])
 console.log("form answ ",answers_list)
      }


      


      const add_answer = (data) => {

        console.log("answer ",answers_list)
        setAnswerList([...answers_list, data])
    
    
       }  
       
       
       const save_evaluation_form = (e) => {
               window.jQuery(function ($){

          setEvName($('[name=evaluation_name]').val())
          setTypeEv($('[name=type_evaluation]').find('option:selected').val())
          setCourse($('[name=course_evaluation]').find('option:selected').val())
          setTopic($('[name=topic_evaluation]').find('option:selected').val())
          

          const new_evaluation={
            name:$('[name=evaluation_name]').val(),
            type:$('[name=type_evaluation]').find('option:selected').val(),
            course:$('[name=course_evaluation]').find('option:selected').val(),
            topic:$('[name=topic_evaluation]').find('option:selected').val(),
            answers:answers_list
          }

          console.log("data send ", new_evaluation)

          
      let enconde_json_form = JSON.stringify(new_evaluation);

      console.log('json ',enconde_json_form)

      axios.defaults.headers.common = { }
      axios.defaults.headers.common = { "Content-Type": "application/json; charset=utf-8"}
      const endpoint = `/api/v1/evaluations`
    
     
      var formData = new FormData();
      formData.append('evaluation', enconde_json_form);
    

      fetch('http://localhost:2022'+endpoint, {
        method: 'POST',
        body: formData
     })


        })

       }


       const setField = (f) => {

       }
    

   const add_reply = (data) => {

    console.log("form ",data)

    var answer_list_copy = [...answers_list]

    answer_list_copy[data.index_r].replies_list = [...answer_list_copy[data.index_r].replies_list,data]

    console.log("new ",answer_list_copy)
    setAnswerList(answer_list_copy)

   }   


   const loadTopics = (course)=>{

        console.log(course.target.value)
        
    const endpoint = '/api/v1/course/course_topics/'+course.target.value
    axios.get(endpoint).then((response) => {
      
      setDisabled(false)
      
      setcomboTList(response.data)



    })


    }


    const changeFieldData = (fl) => {



    }


      
  useEffect(() => {


    const load_combos = () =>{

    const endpoint = '/api/v1/course/created_courses/'+window.localStorage.getItem('userId')
    axios.get(endpoint).then((response) => {
      setcomboCList(response.data)
    })


  }

  load_combos()

  window.jQuery(function ($) {

    $('[data-type=basic]').change(function(){

        console.log($(this).val())

        if($(this).prop('name')==='evaluation_name'){
          setEvName($(this).val())
        }

    })

  })


  }, [])






      let items = [{
        key: 'Header',
        name: 'Header Text',
        icon: 'fa fa-header',
        static: true,
        content: 'Placeholder Text...'
      },
      {
        key: 'Paragraph',
        name: 'Paragraph',
        static: true,
        icon: 'fa fa-paragraph',
        content: 'Placeholder Text...'
      }];


      
 return(
   <div className="container-fluid appf">
   <div className="row headerf">
     <div className="col-sm-4">
    <h2>Evaluation Form</h2>

     </div>

     
     <div className="col-sm-4">


     </div>

     
     <div className="col-sm-4">
     

     </div>
  </div>   
  <div className="evaluation_basic">
   <div className="row">
     <div className="col-sm-12">
       <h6>Evaluation Name: </h6>
       <input className="form-control" name="evaluation_name" type="text" data-type="basic"/>
     </div>
     </div>
     <div className="row">
     <div className="col-md-4">
     <h6>Type:</h6>
     <select name="type_evaluation" className="form-control" data-type="basic">

        <option value="0">Topic Evaluation</option>

        <option value="1">Total Course Evaluation</option>


     </select>
    </div>
    <div className="col-md-4">
      <h6>Course: </h6>
      <select name="course_evaluation" className="form-control" onChange={loadTopics} data-type="basic">

        <option value="">Select course</option>   

        {combo_courses && combo_courses.map((courseItem,index)=>{

          return(<option value={courseItem._id} >{courseItem.title}</option>)   

       })}


     </select>
    </div>
    <div className="col-md-4">
      {combo_topics.length > 0 && (
        
       <div> 
        <h6>Topic: </h6>
   
      <select name="topic_evaluation" className={'form-control '+(disabletp ? "disabled" : '')} data-type="basic">

      {combo_topics && combo_topics.map((tpi, index)=>{

          return(<option value={tpi._id} >{tpi.title}</option>)   


      })}
   
        
     

     </select>

     </div>

 )}  
    </div>
    </div></div>
   <div className="row alx">
    <div className="col-md-12">
      <h2>Answer List</h2>
      </div>
    </div>

      

     {answers_list && answers_list.map((answ,index)=>{ 
        
    return(
      <div className="row">
      <div className="col-md-12">
      
      <div className="answers_container">
      <form id="reply_form" autoComplete="off" onSubmit={handleSubmit2(add_reply)}>
     
       <label>Answer Name: </label>
       <input type="text" name="nameAns" value={answ.name} className="form-control" ref={register2}/>

      <br/>
 
       <b>List replies</b>
       
      <input className="form-control indexA_r" type="hidden" value={index} name={'index_r'} ref={register2}/>
        
       <table className="table ">
       <thead>
       <tr width="100%">
       {answ && answ.reply_type !== 'text_input' && answ.reply_type !== 'number_input' ? (
  
           <th width="60%">Reply name</th>
       ):(<></>)}         
           <th width="20%">Correct</th>
           <th width="20%"></th>
         </tr>
         </thead>
          <tbody className="tbodyR">
      


         {answ.replies_list && answ.replies_list.map((repl, indexRp)=>{
              console.log("lista ",answ.reply_type)

          if(answ.reply_type === 'multiple'){


           return(
           <tr>
            <td width="60%"><input className="form-control name_r" type="text" value={repl.name_r}/></td>
           
            <td width="20%"><input className="form-control correct_r" type="checkbox"/></td>
            <td width="20%">
            <button className="btn btn-delete-ans "><i className="fa fa-trash"></i></button>
            </td>
            </tr>
           ) 

          }


          if(answ.reply_type === 'unique'){


            return(
            <tr>
            {answ && answ.reply_type !== 'text_input' && answ.reply_type !== 'number_input' ? (
             <td width="60%"><input className="form-control name_r" type="text" value={repl.name_r}/></td>
             ):(<></>)}
             <td width="20%"><input className="form-control correct_r" type="radio"/></td>
             <td width="20%">
             <button className="btn btn-delete-ans "><i className="fa fa-trash"></i></button>
             </td>
             </tr>
            ) 
 
           }



         })}

<tr>
{answ && answ.reply_type !== 'text_input' && answ.reply_type !== 'number_input' ? (
   <td width="60%">

          
             
             
       
<input className="form-control name_r" type="text" name={'name_r'} ref={register2}/>
             
            

   </td> ):(<></>)}
            

           {answ && answ.reply_type === 'unique' ? (

  <td width="20%">
    
    <input className="form-control correct_r" type="radio" name="correct" ref={register2}/>
    
    </td>
          
           ):(<></>)}

{answ && answ.reply_type === 'multiple' ? (

<td width="20%"><input className="form-control correct_r" type="checkbox" name="correct" ref={register2}/></td>

):(<></>)}

{answ && answ.reply_type === 'text_input' || answ.reply_type === 'number_input' ? (

<td width="60%"><input className="form-control correct_r" type="text" name="correct" ref={register2}/></td>

):(<></>)}

           <td width="20%">
          
           {answ && answ.reply_type !== 'text_input' && answ.reply_type !== 'number_input' ? (
          
           <button className="btn btn-delete-ans add_answer"><i className="fa fa-plus"></i></button>
           ):(<></>)}
          
          </td>


         </tr>

         </tbody>
       </table>

       </form>
        
 
     </div>
     </div>
     </div>
    
     )})}


    <form id="add_answer_form" onSubmit={handleSubmit(onSubmit)}>

    <div className="row">
     <div className="col-md-6">
      <label>Answer name</label>
      <input className="form-control" placeholder="Input the name of answer" name="name" ref={register}/>
    </div>
    
    
    <div className="col-md-3">
      <label>Answer Type</label>
      <select className="form-control" name="reply_type" ref={register}>

          <option value="unique">Unique reply</option>
          <option value="multiple">Multiple reply</option>
          <option value="text_input">Text reply</option>
          <option value="number_input">Number reply</option>

      </select>
    </div>


<div className="col-md-3">
<button className="btn btn_add_answer"><i className="fa fa-plus"></i> Add Answer</button>

</div>
    
    </div>
    </form>
    
     <div className="row">
       <div className="col-md-12 center">
       <button className="btn" type='button' onClick={save_evaluation_form}>Save Evaluation</button>
         
       </div>
       
       </div> 
    </div>
    
   
 )
}
