import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DataTable from 'react-data-table-component';
import { useForm, ErrorMessage } from 'react-hook-form'
import './Evaluations.scss'
const ld = require('lodash');

const columns = [
  {
    name: 'Title',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Slug',
    selector: 'slug',
    sortable: true,
    right: true,
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

  const { register, errors, handleSubmit ,setValue} = useForm()
  const [success, setSuccess] = useState(null)
  const [list_add_evaluations, setAddEvaluationsList] = useState([])
  const [list_evaluations, setEvaluationsList] = useState([])
  const [list_add_evaluations_parents, setParentsList] = useState([])

  useEffect(() => {
    const endpoint = '/api/v1/add_evaluations_all'
    axios.get(endpoint).then((response) => {
      setEvaluationsList(response.data)

      let cparents = ld.filter(response.data, { parent: "0" });

      setParentsList(cparents)

    })
  }, [])

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', state.selectedRows);
    setValue('title',state.selectedRows[0].title)

  };


  const onSubmit = (data,e) => {

        axios
          .post('/api/v1/add_evaluations', { ...data, confirm_success_url: '/' })
          .then((response) => {
            if (response.data.result === 'error') {
              setSuccess(false)
          
            } else {
              setSuccess(true)
              e.target.reset()
              setAddEvaluationsList([... list_add_evaluations, data])
              setTimeout(function(){
              let cparents = ld.filter([... list_add_evaluations, data], { parent: "0" });
              console.log("new parents ",cparents)
             
              setParentsList(cparents)
                },1000)
            }
          })
          .catch((error) => {
            if (error.response) {
              //console.log(error.response.data)
            }
          })
      }

 return(
   <div className="row">
      <div className="col-md-6 col-xs-12"></div>
      
      <div className="col-md-6 col-xs-12">
      <div className="cont">
          <h4>search</h4>

            <input className="form-control"/>
            <button className="btn btn-sm btn-default"><i className="fa fa-search"> </i></button>
      
      </div>
        
        </div>

    <div className="col-md-12">
      <button className="btn">New evaluation</button> 
      <button className="btn">Delete</button>

    </div>
      
      <div className="col-md-12 col-xs-12">
      <DataTable
        selectableRows
        title="List Evaluations"
        columns={columns}
        data={[]}
        onSelectedRowsChange={handleChange}
      />
      </div>
   </div>
 )
}
