import React, { useState, useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel2'
import { useSelector } from 'react-redux'
import { Button, Segment } from 'semantic-ui-react'

import course1 from '../../images/courses/img-1.jpg'
import course2 from '../../images/courses/img-2.jpg'
import course3 from '../../images/courses/img-3.jpg'

import './CourseCarousel.scss'

const carouselOptions = {
  items: 10,
  loop: true,
  margin: 20,
  nav: true,
  dots: false,
  navText: [
    "<i class='uil uil-angle-left'></i>",
    "<i class='uil uil-angle-right'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    300:{
      items: 1
    },
    600: {
      items: 3
    },
    1000: {
      items: 4
    },
    1200: {
      items: 4
    },
    1400: {
      items: 6
    }
  }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default ({ sectionTitle, courses }) => {
  const translate = useSelector((state) => state.translate)
  console.log('course list', courses)

  return (
    <div className="section3125 mt-30" key={sectionTitle}>
      <h2 className="item_title">{sectionTitle}</h2>
      <a href="#0" className="see150">
        {translate.GENERAL.SEE_ALL}
      </a>
      <div className="la5lo1">
        <OwlCarousel
          key={sectionTitle}
          options={carouselOptions}
          className="owl-carousel featured_courses owl-theme"
        >
          {courses &&
            courses.map(({ id, title, subtitle, category }) => {
              return (
                <div className="item" key={id}>
                  <div className="fcrse_1 mb-20">
                    <a href="dashboard/course" className="fcrse_img">
                      <img src={course1} alt="" />
                    </a>
                    <div className="fcrse_content">
                      <h4 className="crse14s">{title}</h4>
                      <p>{subtitle}</p>
                      <p className="crse-cate mt-10 category">
                        {category && category.name}
                      </p>
                      <div className="mt-40 row">
                      <div className="col-sm-6  col-xs-6 ">
                      <Button content='Not started' basic />

                      </div>
                        <div className="col-sm-6  col-xs-6 ">
                          {/* {action === 'Start' ? (
                          <span className="green semi-bold">{action}</span>
                        ) : (
                          <span className="blue semi-bold">{action}</span>
                        )} */}
                         <Button inverted color='blue'>Start</Button>
        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </OwlCarousel>
      </div>
    </div>
  )
}
