import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../common/Header/Header'
import Nav from '../common/Nav/Nav'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData ,setMicoursesUserData} from '../../reducers/index'
import AuthManager from '../../helpers/AuthManager'


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  let history = useHistory()

  const userData = useSelector((store) => store.user)
  // const loading = useSelector((store) => store.loading)
  const dispatch = useDispatch()
  // const [loading, setLoading] = useState(null)

  useEffect(() => {
    // setLoading(true)
    // //console.log('dashboar props', props)
    // axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
    getUserData()
  }, [])

  function getUserData() {
    AuthManager.hasToken()
    const userId = window.localStorage.getItem('userId')
    if (userId) {
      axios
        .get(`/api/users/${userId}`)
        .then((response) => {
          dispatch(setUserData({ data: response.data }))
          dispatch(setMicoursesUserData(response.data.my_courses))
          // setLoading(false)
        })
        .catch((error) => {
          dispatch(setUserData(error))
          history.push('/login')
        })
    } else {
      history.push('/login')
    }
  }

  return (
    <div>
      {userData && userData.profile && (
        <React.Fragment>
          <Nav userData={userData} />
          <Header userData={userData} />

          <div className="wrapper">
            <div className="sa4d25">
              {/* <h1>Loading: {store.getState().loading}</h1> */}

              <BrowserView>
              <div key="xx2" className="container-fluid" >{props.children}</div>
              </BrowserView>

              <MobileView>
              <div key="xx2" className="mob" >{props.children}</div>
              </MobileView>
            
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}
