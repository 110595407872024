import React from 'react'
import RegisterForm from '../components/auth/RegisterForm'
import AuthLayout from '../components/layout/AuthLayout'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

export const register = () => {
  return(
    
      <GoogleReCaptchaProvider
        reCaptchaKey="6LfUG58aAAAAALwe41YLcr85T4JuCo4e4PvXVs4O"
      >
        <AuthLayout>
        <RegisterForm />

        <GoogleReCaptcha />
        </AuthLayout>
      </GoogleReCaptchaProvider>
    

    
  )
}
