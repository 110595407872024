import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import CategoriesPanel from './Company/CategoriesAdmin'

import { useStore, useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";




export const CategoriesAdmin = (props) => {
 
  
  return (
    <DashboardLayout>
     <CategoriesPanel/>
    </DashboardLayout>
  )
}
