import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { useForm, ErrorMessage } from 'react-hook-form'
import { useSelector } from 'react-redux'
import Dropdown from '../../../components/common/Dropdown/Dropdown'
import { DefaultEditor } from 'react-simple-wysiwyg'
import { HtmlEditor, MenuBar } from '@aeaton/react-prosemirror'
import { options, menu } from '@aeaton/react-prosemirror-config-default'
import ReactPlayer from 'react-player'

import './LessonForm.scss'


const languages = [
  {
    id: 'EN',
    name: 'English'
  },
  {
    id: 'ES',
    name: 'Español'
  },
  {
    id: 'PT',
    name: 'Portugues'
  },
  {
    id: 'DE',
    name: 'German'
  },
  {
    id: 'FR',
    name: 'French'
  },
  {
    id: 'IT',
    name: 'Italiano'
  }
]


// eslint-disable-next-line import/no-anonymous-default-export
export default ({keyF, courseId, nextStep, lessons_g }) => {
  const { register, errors, handleSubmit, setValue } = useForm()
  const translate = useSelector((state) => state.translate)

  const [type_l, setTypeL] = useState(0)
  

  const setLessonType = ((type) => {

    console.log(type.target.value)
    setTypeL(type.target.value)

  })


  const updateEditor = ((data)=>{

    window.jQuery(function ($) {


      $('#htmldesh').val(data)
      $('#htmldesh').val() 
    })

   
  })

  return (
    <div className="topic-form">
      
      <form autoComplete="off" className="form_lesson" name="fl" id="flw">
      
        <div className="course__form shadow-white">
          <div className="general_info10">
            <div className="row">
              <div className="col-md-12">
                <h2 className="title pb-20 dark">
                  <i className="fa fa-bookmark" />
                 New Lesson
                </h2>
                <hr className="" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="ui search focus mt-30 lbel25">
                  <label>Lesson Title*</label>
                  <div className="ui input swdh19">
                    <input
                      className="prompt"
                      type="text"
                      placeholder={translate.GENERAL.INSERT_TITLE}
                      name="title"
                      maxLength="60"
                      id="title"
                      ref={register({
                        required: translate.GENERAL.REQUIRED
                      })}
                    />
                    <div className="badge_num">60</div>
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" />
                </div>
              </div>
            </div>

          

            <div className="row">
            <div className="col-lg-6 col-md-6">
                <div className="ui search focus mt-30 lbel25">
                  <label>Lesson duration*</label>
                  <div className="ui input swdh19">
                  <input
                      className="prompt"
                      type="time"
                      placeholder="Insert time of duration"
                      name="duration"
                      maxLength="60"
                      id="duration"
                      ref={register({
                        required: translate.GENERAL.REQUIRED
                      })}
                    />
                
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" /> 
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="ui search focus mt-30 lbel25">
                  <label>Lesson type*</label>
                  <div className="ui input swdh19">
                      

                 <Dropdown
                  name="lesson_type"
                  onChange={setLessonType}
                  register={register({
                    required: 'This field is required'
                  })}
                  options={[{_id:1,title:'Video Lesson'},{_id:2,title:'Text Lesson'}]}
                />
                
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" /> 
                </div>
              </div>
            </div>

             {/* {type_l && type_l === '2' && (      */}
           
            <div className="row stc">
              <div className="col-lg-12 col-md-12">
                <div className="ui search focus mt-30 lbel25">
                  <label>Lesson Text content*</label>
                  <div className="desc-editor">
                    <HtmlEditor
                      options={options}
                      value=""
                      ref={register({})}
                      name="description"
                      onChange={updateEditor}
                      render={({ editor, view }) => (
                        <div>
                          <MenuBar menu={menu} view={view} />
                          {editor}
                        </div>
                      )} 
                    />
                  </div>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="title" /> 
                </div>
              </div>
            </div>

            {/* )}  */}

          {/* {type_l && type_l === '1' && (               */}
            <div className="video_container row videoport row">
            <label>Lesson Video content*</label><br/>
            <input
                      className="prompt form-group"
                      type="text"
                      placeholder="Enter url video"
                      name="video_url"
                      maxLength="60"
                      id="lesson_video"
                      ref={register({
                        required: translate.GENERAL.REQUIRED
                      })}
                    />
    <ReactPlayer 
      url="https://www.youtube.com/watch?v=Qk7yZBwpCD8" 
      controls={true} 
      width='100%' 
      height='400px'
    />

      
			
			</div>
               {/* )}        */}

            <div className="row mt-20">
              <div className="col-md-12">
                <hr />
              </div>
            </div>
           
           
            
          </div>
        </div>
        <input name="lesson_description" type="hidden" id="htmldesh"/>
      </form>
    </div>
  )
}
