import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios'
import { useForm, ErrorMessage } from 'react-hook-form'
import { Dropdown } from 'semantic-ui-react'
import Quiz from '../../components/QuizMain';

import './Form_evaluation.scss'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const ld = require('lodash');

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {

const [evaluationDT, setEvaluation] = useState({})

const [question_step, setstep] = useState(1)
const [score, setScore] = useState(0)

const [course,set_c]=useState(props.course)
      
  useEffect(() => {


    const load_evaluation = () =>{

    const endpoint = '/api/v1/evaluations/get/'+props.topic+'/'+props.course
    axios.get(endpoint).then((response) => {

		setEvaluation(response.data)
    })


  }

  load_evaluation()


  }, [])


const nextStep = () => {


  if((evaluationDT.answers.length ) >= question_step){

    
  let next = question_step + 1

  setstep(next)

  }

}

      
 return(
	<div className="as" id="ev_cinte">
  
    

  {evaluationDT && (<div>
    <div className="row">
   <h3>Course name: {evaluationDT.course_title}</h3>
   </div>
   <div className="row">
   <h4>Topic evaluation name: {evaluationDT.topic_title}</h4>
   </div>
<div className="cleafix"></div>


   <div className="row">
   


   {evaluationDT.answers && evaluationDT.answers.map((item, index)=>{

     //return(<div className="col-md-12"><h3>{item.name}?</h3></div>)   


   })}


   
{evaluationDT.answers ? (
<>

{question_step && question_step <= evaluationDT.answers.length ? (
<div>
<div className="col-md-12">    
 <div align="center"> <p>Questions: {(question_step+'/'+evaluationDT.answers.length)}</p>  </div>
  
  <p>{evaluationDT.answers[question_step -1].name}?</p>

    
    </div> 

    <div className="col-md-12">   
    <ul>
      {evaluationDT.answers[question_step-1].replies_list && evaluationDT.answers[question_step-1].replies_list.map((itemr, index)=>{

        if(evaluationDT.answers[question_step-1].reply_type === 'multiple'){
        return(<li><input type="checkbox" name="xsd" className=""/>  {itemr.name_r}</li>)

        }

        if(evaluationDT.answers[question_step-1].reply_type === 'unique'){
          return(<li><input type="radio" name="xsd" className=""/>  {itemr.name_r}</li>)
  
          }


          if(evaluationDT.answers[question_step-1].reply_type === 'text_input'){
            return(<li><input type="text" name="xsd" className="form-control"/>  {itemr.name_r}</li>)
    
            }

            if(evaluationDT.answers[question_step-1].reply_type === 'number_input'){
              return(<li><input type="number" name="xsd" className=""/>  {itemr.name_r}</li>)
      
              }


      })}
      

    </ul>
    </div> 

    <div className="col-md-12">   
  
    <Link to={'/dashboard/course/?courseid='+course} >Back to course</Link>  |  

    <Link onClick={nextStep} >{question_step === evaluationDT.answers.length ?('Finish'):('Next')} </Link> 
  
       </div>

</div>
      ):( <div className="finalPage">
                                     
                                    <h1>You have completed the evaluation!</h1>
                                       <p>Your score is: {score} of {evaluationDT.answers.length}</p>
                                       <p>Thank you!</p>
                                       <Link to={'/dashboard/course/?courseid='+course} >Back to course</Link>
                                      </div>)}

</>

):(<></>)}



     
  
   </div> 
   
  </div> )}
   

  </div> 

 )
}
