import { createSlice, configureStore } from '@reduxjs/toolkit'
import { translate } from '../translate'

const initialState = {
  data: {},
  user: {},
  my_courses:[],
  courseL:{},
  loading: false,
  error: false,
  count: 0,
  translate: translate,
  openLesson:{}
}

const rootSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    fetchProgress(state) {
      state.loading = true
      // state.error = false
      // state.data = []
    },
    fetchSuccess(state, action) {
      state.data = action.payload.data
      state.loading = false
    },
    fetchFailure(state, action) {
      state.error = action.payload.error
      state.loading = false
    },
    setUserData(state, action) {
      state.user = action.payload.data
      console.log(state.user)
    },
    setMicoursesUserData(state, action){
      state.my_courses = action.payload
    },
    setCourseData(state, action) {
      state.courseL = action.payload
    },
    setTranslate(state, action) {
      state.translate = action.payload
    },
    setActiveLesson(state, action) {
      
      state.openLesson = action.payload
    }
  }
})

const store = configureStore({
  reducer: rootSlice.reducer
})

export const {
  fetchProgress,
  fetchSuccess,
  fetchFailure,
  setUserData,
  setTranslate,
  setCourseData,
  setActiveLesson,
  setMicoursesUserData
} = rootSlice.actions

export default rootSlice.reducer
export { store }
// export const selectData = (state) => state.user.datac
// //console.log(state)
