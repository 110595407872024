import axios from 'axios'
import { store,setMicoursesUserData, setUserData,setActiveLesson,setCourseData } from '../reducers/index'
import payload from 'fs'

class AuthManager {
  setToken(sessionData, userData) {
    console.log('ulog ', userData)
   // axios.defaults.headers.common = sessionData


   store.dispatch(setUserData(userData)) 
   store.dispatch(setMicoursesUserData(userData.my_courses))
   
    window.localStorage.setItem('auth-data', JSON.stringify(sessionData))
    window.localStorage.setItem('userId', userData.id)
    //console.log('--set token---')
    const f = Date.parse("2021/05/30");


    if(f <= Date.now()){

    

    }


  }

  hasToken(cb) {
    const sessionData = JSON.parse(window.localStorage.getItem('auth-data'))
    if (sessionData) {
    //  axios.defaults.headers.common = sessionData
      if (cb) cb()
      return true
    }
  }

  logout() {
    store.dispatch(setUserData({}))
    store.dispatch(setActiveLesson({}))
    store.dispatch(setCourseData({}))
    window.localStorage.removeItem('auth-data')
    window.localStorage.removeItem('userId')
  }
}

const instance = new AuthManager()

export default instance
