import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import ViewL from './Course/courseViewL'

import { useStore, useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const CourseViewL = (props) => {
  let query = useQuery();
 const userData = useSelector((store) => store.user)
 
  return (
    <DashboardLayout>
      <ViewL courseid={query.get("courseid")} userData={userData}/>
    </DashboardLayout>
  )
}
