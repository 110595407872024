import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel2'

import course1 from '../../images/courses/Rectangle-6.png'
import course2 from '../../images/courses/img-2.jpg'
import course3 from '../../images/courses/img-3.jpg'

const carouselOptions = {
  items: 10,
  loop: false,
  margin: 30,
  nav: true,
  dots: false,
  navText: [
    "<i class='uil uil-angle-left'></i>",
    "<i class='uil uil-angle-right'></i>"
  ],
  responsive: {
    0: {
      items: 1
    },
    600: {
      items: 1
    },
    1000: {
      items: 1
    },
    1200: {
      items: 1
    },
    1400: {
      items: 1
    }
  }
}

export default () => {
  useEffect(() => {})

  return (
    <React.Fragment>
      <div className="la5lo1 shadow shadow-seamless-white">
        <OwlCarousel
          options={carouselOptions}
          className="owl-carousel courses_performance owl-theme"
        >
          <div className="item">
            <div className="fcrse_1">
              <a href="#0" className="fcrse_img">
                <img src={course1} alt="" />
                <div className="course-overlay"></div>
              </a>
              <div className="fcrse_content">
                <div className="vdtodt">
                  <span className="vdt14">First 2 days 22 hours</span>
                </div>
                <a href="#0" className="grey">
                  Complete Python Bootcamp: Go from zero to hero in Python 3
                </a>
                <div className="allvperf">
                  <div className="crse-perf-left">View</div>
                  <div className="crse-perf-right">1.5k</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Purchased</div>
                  <div className="crse-perf-right">150</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Total Like</div>
                  <div className="crse-perf-right">1k</div>
                </div>
                <div className="auth1lnkprce">
                  <a href="#0" className="grey card-actions">
                    Go to course analytics
                  </a>
                  <a href="#0" className="grey card-actions">
                    See comments (875)
                  </a>
                  <a href="#0" className="grey card-actions">
                    See Reviews (105)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="fcrse_1">
              <a href="#0" className="fcrse_img">
                <img src={course2} alt="" />
                <div className="course-overlay"></div>
              </a>
              <div className="fcrse_content">
                <div className="vdtodt">
                  <span className="vdt14">Second 4 days 9 hours</span>
                </div>
                <a href="#0" className="crse14s">
                  The Complete JavaScript Course 2020: Build Real Projects!
                </a>
                <div className="allvperf">
                  <div className="crse-perf-left">View</div>
                  <div className="crse-perf-right">175k</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Purchased</div>
                  <div className="crse-perf-right">1k</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Total Like</div>
                  <div className="crse-perf-right">85k</div>
                </div>
                <div className="auth1lnkprce">
                  <a href="#0" className="grey card-actions">
                    Go to course analytics
                  </a>
                  <a href="#0" className="grey card-actions">
                    See comments (915)
                  </a>
                  <a href="#0" className="grey card-actions">
                    See Reviews (255)
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="fcrse_1">
              <a href="#0" className="fcrse_img">
                <img src={course3} alt="" />
                <div className="course-overlay"></div>
              </a>
              <div className="fcrse_content">
                <div className="vdtodt">
                  <span className="vdt14">Third 6 days 11 hours:</span>
                </div>
                <a href="#0" className="crse14s">
                  Beginning C++ Programming - From Beginner to Beyond
                </a>
                <div className="allvperf">
                  <div className="crse-perf-left">View</div>
                  <div className="crse-perf-right">17k</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Purchased</div>
                  <div className="crse-perf-right">25</div>
                </div>
                <div className="allvperf">
                  <div className="crse-perf-left">Total Like</div>
                  <div className="crse-perf-right">15k</div>
                </div>
                <div className="auth1lnkprce">
                  <a href="#0" className="grey">
                    Go to course analytics
                  </a>
                  <a href="#0" className="grey">
                    See comments (155)
                  </a>
                  <a href="#0" className="grey">
                    See Reviews (15)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </React.Fragment>
  )
}
