import React from 'react'
import UploadMedia from '../../components/upload/Upload'
import emptyImg from '../../images/courses/add_img.jpg'
import emptyVideo from '../../images/courses/add_video.jpg'
import { useForm, ErrorMessage } from 'react-hook-form'
import axios from 'axios'

import './Media.scss'
import { useSelector } from 'react-redux'

// eslint-disable-next-line import/no-anonymous-default-export
export default ({nextStep,course}) => {
  const translate = useSelector((state) => state.translate)
  const { register, errors, handleSubmit, setValue } = useForm()
  

console.log("cresid ",course)

  const mediaItems = [
    {
      key: 'promo-img',
      defaultImg: emptyImg,
      title: 'IMG_TITLE',
      description: 'IMG_DESC',
      fileTypes: ['jgp', 'jpg', 'jpeg', 'png', 'gif']
    },
    {
      key: 'promo-video',
      defaultImg: emptyVideo,
      title: 'VIDEO_TITLE',
      description: 'VIDEO_DESC',
      fileTypes: ['mp4', 'mpg', 'mpeg', 'avi'],
      url_video:""
    }
  ]

  const onSubmit = async (data) =>{
   console.log(data)
    //nextStep(data)



    const formData = new FormData();
    
    formData.append("image_title", data.IMG_TITLE);
    formData.append("video_title", data.VIDEO_TITLE);
    axios.defaults.headers.common = {}
    
    axios.defaults.headers.common = { "Content-Type": "multipart/form-data"}
    console.log(axios.defaults.headers.common)

    console.log('course form data', data)
    const endpoint = `/api/v1/course/upload`
    await axios.post(endpoint, formData,{ 
      "Content-Type": "multipart/form-data"
    }).then((response) => {
    
      console.log("http")
     // window.localStorage.setItem('idcs', response.data.course_s._id)
      nextStep({media:[]})
     
    
    
    })

  }



  return (
    <div className="tab-from-content mt-50 media-container">
      <div className="title-icon">
        <h2 className="title">
          <i className="fa fa-picture-o"></i> {translate.MEDIA.PREVIEW}
        </h2>
      </div>
      <div className="shadow-white mb-30">
        <div className="view_info10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-12">
           
              {mediaItems.map((item) => {
                return <UploadMedia {...item} key={item.key} register={register} />
              })}
            </div>
          </div>
          
        <div className="row mt-30">
          <div className="col-lg-12 col-md-12 text-right">
            <div className="shadow-white px-30">
              <button className="save_btn" type="submit">
                Next Step
              </button>
            </div>
          </div>
        </div>

          </form>
            
        </div>
      </div>
    </div>
  )
}
