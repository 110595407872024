import React from 'react'
import Register from '../components/auth/RegisterCompanyForm'
import AuthLayout from '../components/layout/AuthLayout'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

export const registerCompany = () => {
  return(
    
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfUG58aAAAAALwe41YLcr85T4JuCo4e4PvXVs4O"
    >
      <AuthLayout>
      <Register/>

      <GoogleReCaptcha />
      </AuthLayout>
    </GoogleReCaptchaProvider>
  

  
)
}
