import axios from 'axios'
import React, { useState } from 'react'
import { useForm, ErrorMessage } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default () => {
  const { register, errors, handleSubmit, triggerValidation } = useForm()
  const [authError, setAuthError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [tsuccess, setTsuccess] = useState(null)
  const translate = useSelector((state) => state.translate)

  const onSubmit = (data) => {
    data.redirect_url = `${window.location.origin}/reset-password`
    axios.defaults.headers.common = {}
    axios
      .post('/auth/password', data, {'Content-Type': 'application/json; charset=utf-8'})
      .then((response) => {
        setSuccess(true)
        setTsuccess(response.data.text)
      })
      .catch((error) => {
        if (error.response) {
          //console.log(error.response.data)
          setAuthError(error.response.data.errors)
        }
      })
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      triggerValidation()
    }
  }

  return (
    <React.Fragment>
      <div className="sign_form">
        <h2>{translate.FORGOT_PASSWORD.TITLE}</h2>
        {!success ? (
          <div>
            <div className="error-message danger">
              {authError && authError.join(',')}
            </div>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="ui focus mt-15">
                <div className="ui left icon input swdh95">
                  <input
                    className="prompt"
                    type="text"
                    name="email"
                    id="email" 
                    maxLength="40"
                    onKeyPress={(e) => handleKeyPress(e)}
                    placeholder={translate.GENERAL.EMAIL_ADDRESS}
                    ref={register({
                      required: translate.GENERAL.REQUIRED,
                      maxLength: 40,
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: translate.GENERAL.INVALID_EMAIL
                      }
                    })}
                  />
                  <i className="uil uil-envelope icon icon2"></i>
                </div>
                <div className="error-message">
                  <ErrorMessage as="span" errors={errors} name="email" />
                </div>
              </div>
              <button className="login-btn" type="submit">
                {translate.FORGOT_PASSWORD.RESET_PASSWORD}
              </button>
            </form>
            <p className="mb-0 mt-30">
              {translate.GENERAL.GO_BACK}{' '}
              <Link to="/login">{translate.GENERAL.SIGN_IN}</Link>
            </p>
          </div>
        ) : (
          <div>
          <h4 className="success">
            {/* We sent you and email with the instructions. */}
            {tsuccess}
          </h4>
            <Link to="/login">{translate.GENERAL.SIGN_IN}</Link>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
