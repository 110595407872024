import React from 'react'
import DashboardLayout from '../components/layout/DashboardLayout'
import FormEvaluationC from './Student/Form_evaluation'

import { useStore, useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FormEvaluationPage = (props) => {
  let query = useQuery();
  const userData = useSelector((store) => store.user)

  return (
    <DashboardLayout>
      <FormEvaluationC course={query.get("course")} topic={query.get("topic")} userData={userData} />
    </DashboardLayout>
  )
}
