import React, { useEffect, useState } from 'react'
import AppRouter from './components/common/AppRouter'
import Interceptor from './helpers/Interceptors'
import Loader from './components/common/Loader/Loader'

import { Provider } from 'react-redux'
import { store } from './reducers/index'

import './styles/global.scss'
import './App.css'

const App = () => {
  window.store = store
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    //console.log('app entry point')
    store.subscribe(() => {
      const loadingState = store.getState().loading
     // setLoading(loadingState)
    })
    new Interceptor(store)
  }, [])

  return (
    <Provider store={store}>
      <section className="masamo-app">
        {loading && <Loader />}
        <AppRouter />
      </section>
    </Provider>
  )
}

export default App
