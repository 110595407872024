import React, { useState, useEffect } from 'react'
import { Button, Comment, Form, Header } from 'semantic-ui-react'
import { store, setCourseData,setActiveLesson } from '../../reducers/index'
import { useStore, useSelector } from 'react-redux'
import axios from 'axios'
import qs from 'qs'


const load_comments = async (id_lesson) =>{
    
  console.log('id lc : ',id_lesson )    

  const sessionData = JSON.parse(window.localStorage.getItem('auth-data'))

  console.log(sessionData)

    if (sessionData) {
      axios.defaults.headers.common = {}
    
  }
 
   const endpoint = '/api/v1/comments/'+id_lesson

   console.log("hax ",axios.defaults.headers.common)
   await axios
    //  .post(endpoint, qs.stringify({id_course:props.courseid, id_user:props.userData.id}))
    .get(endpoint, {lesson:id_lesson})
    .then((response) => {
      
      return response.data.comments
  
    })

  }


  
  // 


const CommentsLessons = (props) =>{ 

  const [comment, setCcomment] = useState({comment_text:''})
  const [comments, set_comment_list] = useState([])
  const [lesson_active, setActiveLesson] = useState({})
  const [user_data, setUserData] = useState({})

  const user_login = useSelector((store) => store.user)  
  const active_lesson_actual = useSelector((store) => store.openLesson)
  console.log("la 1 ",active_lesson_actual)
  
const handleChange = (e, { comment_text, value }) => setCcomment({comment_text : value })




const handleSubmit = () => {
const {comment_text} = comment 
const id_lesson = active_lesson_actual._id  
const id_user = user_login.id

console.log("id_user ",id_user)


    console.log('comment dsta: ',comments)    

    const sessionData = JSON.parse(window.localStorage.getItem('auth-data'))

    console.log(sessionData)
  
      if (sessionData) {
        axios.defaults.headers.common = {}
      
    }
   
     const endpoint = '/api/v1/comments/'
  
     console.log("hax ",axios.defaults.headers.common)
     axios
      //  .post(endpoint, qs.stringify({id_course:props.courseid, id_user:props.userData.id}))
      .post(endpoint, {lesson:id_lesson,text:comment_text, user:id_user,parent:'0'})
      .then((response) => {
        //load_comments(active_lesson_actual._id)

        set_comment_list([...comments,{comment_text:comment_text}])
  

      })


  }
    useEffect(() => {
     
      
    setTimeout(()=>{
      const id_lesson = active_lesson_actual._id
      console.log("idlf ",id_lesson)

     
      load_comments(id_lesson)  
      
    },5500)


    }, [])
   
    
  return (
  <Comment.Group>

    <h3>Lesson comments</h3>

<div className="comments-container">
    
    
    {comments && comments.map((item, index) => {

    return(  
    <Comment>
      <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/matt.jpg' />
      <Comment.Content>
        <Comment.Author as='a'>{user_login && user_login.name}</Comment.Author>
        <Comment.Metadata>
          <div>Today at 5:42PM</div>
        </Comment.Metadata>
        <Comment.Text>{item.comment_text}</Comment.Text>
        <Comment.Actions>
          <Comment.Action>Reply</Comment.Action>
        </Comment.Actions>
      </Comment.Content>
    </Comment>
    )

})}


{/* 
    <Comment>
      <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
      <Comment.Content>
        <Comment.Author as='a'>Elliot Fu</Comment.Author>
        <Comment.Metadata>
          <div>Yesterday at 12:30AM</div>
        </Comment.Metadata>
        <Comment.Text>
          <p>This has been very useful for my research. Thanks as well!</p>
        </Comment.Text>
        <Comment.Actions>
          <Comment.Action>Reply</Comment.Action>
        </Comment.Actions>
      </Comment.Content>
      <Comment.Group>
        <Comment>
          <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/jenny.jpg' />
          <Comment.Content>
            <Comment.Author as='a'>Jenny Hess</Comment.Author>
            <Comment.Metadata>
              <div>Just now</div>
            </Comment.Metadata>
            <Comment.Text>Elliot you are always so right :)</Comment.Text>
            <Comment.Actions>
              <Comment.Action>Reply</Comment.Action>
            </Comment.Actions>
          </Comment.Content>
        </Comment>
      </Comment.Group>
    </Comment>

    <Comment>
      <Comment.Avatar src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
      <Comment.Content>
        <Comment.Author as='a'>Joe Henderson</Comment.Author>
        <Comment.Metadata>
          <div>5 days ago</div>
        </Comment.Metadata>
        <Comment.Text>Dude, this is awesome. Thanks so much</Comment.Text>
        <Comment.Actions>
          <Comment.Action>Reply</Comment.Action>
        </Comment.Actions>
      </Comment.Content>
    </Comment> */}

    </div>


    <Form reply onSubmit={handleSubmit}>
      <Form.TextArea 
      name="comment_text"
      onChange={handleChange}
      value={comment.comment_text}/>
      <Form.Button content='Add Reply' labelPosition='left' icon='edit' primary />
    </Form> 
  </Comment.Group>
)
  }

export default CommentsLessons
